import React, { useState, useEffect, useRef } from 'react';
import { List, Button, Modal, message, Popconfirm, Card, Input, Select, Spin } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, DownloadOutlined, SwapOutlined, SaveOutlined } from '@ant-design/icons';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { useDatabase } from '../contexts/DatabaseContext';
import { useLocation } from 'react-router-dom';
import '../assets/styles/reports.css';
import { convertToTable, convertToWord } from '../services/openaiService';
import * as XLSX from 'xlsx';
import { Document, Packer, Paragraph, TextRun, Table, TableRow, HeadingLevel,TableCell } from "docx";
  



const { Option } = Select;

const Reports = () => {
  const { reports, updateReport, deleteReport } = useDatabase();
  const [templates, setTemplates] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);
  const [isCustomizeModalVisible, setIsCustomizeModalVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState('Table');
  const [editedTitle, setEditedTitle] = useState('');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [exportFormat, setExportFormat] = useState('word');
  const [reportToExport, setReportToExport] = useState(null);
  const location = useLocation();
  const editorRef = useRef(null);

  const sunEditorOptions = {
    buttonList: [
      ['undo', 'redo'],
      ['font', 'fontSize', 'formatBlock'],
      ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
      ['removeFormat'],
      '/',
      ['fontColor', 'hiliteColor'],
      ['outdent', 'indent'],
      ['align', 'horizontalRule', 'list', 'table'],
      ['fullScreen', 'showBlocks', 'codeView'],
      ['preview', 'print'],
      ['save', 'template'],
    ],
    table: {
      maxWidth: '100%',
    },
    formats: [
      'p', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
      {
        tag: 'ol',
        class: 'sun-editor-ordered-list'
      },
      {
        tag: 'ul',
        class: 'sun-editor-unordered-list'
      },
      {
        tag: 'li',
        command: 'formatBlock',
        class: 'sun-editor-list-item'
      }
    ],
  };

  // useEffect(() => {
  //   console.log('Reports data:', reports);
  // }, [reports]);

  const ensureString = (value) => {
    if (typeof value === 'string') {
      return value;
    } else if (Array.isArray(value) && value.length > 0) {
      return value[0].toString();
    } else if (value && typeof value === 'object') {
      return JSON.stringify(value);
    }
    return '';
  };

  const handleReportClick = (report) => {

    setEditedTitle(report.title);

    const bodyContent = ensureString(report.summary);
    const updatedReport = { ...report, summary: bodyContent };
    setSelectedReport(updatedReport);
    
    if (editorRef.current) {
      editorRef.current.setContents(bodyContent);
    }
  };

  const handleContentChange = (content) => {
    setSelectedReport(prevReport => ({
      ...prevReport,
      summary: ensureString(content)
    }));
  };

  const handleSaveReport = () => {
    // console.log('Saving report:', selectedReport);
    updateReport(selectedReport);
    message.success('Report updated successfully');
  };

  const handleTitleChange = (e) => {
    setEditedTitle(e.target.value);
  };

  const handleTitleSave = () => {
    const updatedReport = { ...selectedReport, title: editedTitle };
    setSelectedReport(updatedReport);
    updateReport(updatedReport);
    setIsEditingTitle(false);
    message.success('Report title updated successfully');
  };

  const handleDelete = (reportId) => {
    // console.log('Deleting report with ID:', reportId);
    deleteReport(reportId);
    setSelectedReport(null);
    message.success('Report deleted successfully');
  };

  const handleExportClick = (report) => {
    setReportToExport(report);
    setIsExportModalVisible(true);
  };

  const handleExportCancel = () => {
    setIsExportModalVisible(false);
    setReportToExport(null);
    setExportFormat('word');
  };

  const handleExportOk = () => {
    setIsExportModalVisible(false);
    handleDownload(reportToExport, exportFormat);
  };


  const convertHtmlToDocx = async (htmlContent) => {
    console.log('Converting HTML to Word:', htmlContent);
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');
      const body = doc.body;
  
      const paragraphs = [];
  
      const traverseNodes = (node, level = 0) => {
        const children = [];
        node.childNodes.forEach((child) => {
          if (child.nodeType === Node.TEXT_NODE) {
            children.push(new TextRun(child.textContent));
          } else if (child.nodeType === Node.ELEMENT_NODE) {
            switch (child.tagName.toLowerCase()) {
              case 'b':
              case 'strong':
                children.push(new TextRun({ text: child.textContent, bold: true }));
                break;
              case 'i':
              case 'em':
                children.push(new TextRun({ text: child.textContent, italics: true }));
                break;
              case 'u':
                children.push(new TextRun({ text: child.textContent, underline: {} }));
                break;
              case 'p':
                console.log('Paragraph:', child);
                paragraphs.push(new Paragraph({
                  children: traverseNodes(child)
                }));
                break;
              case 'br':
                children.push(new TextRun({ text: '\n' }));
                break;
              case 'ul':
                child.childNodes.forEach((li) => {
                  if (li.tagName && li.tagName.toLowerCase() === 'li') {
                    paragraphs.push(new Paragraph({
                      children: traverseNodes(li, level + 1),
                      bullet: { level: level }
                    }));
                  }
                });
                break;
              case 'li':
                children.push(new Paragraph({
                  children: traverseNodes(child, level),
                  bullet: { level: level }
                }));
                break;
              case 'table':
                const rows = [];
                child.childNodes.forEach((tr) => {
                  if (tr.tagName && tr.tagName.toLowerCase() === 'tr') {
                    const cells = [];
                    tr.childNodes.forEach((td) => {
                      if (td.tagName && (td.tagName.toLowerCase() === 'td' || td.tagName.toLowerCase() === 'th')) {
                        cells.push(new TableCell({
                          children: [new Paragraph(traverseNodes(td))]
                        }));
                      }
                    });
                    rows.push(new TableRow({ children: cells }));
                  }
                });
                paragraphs.push(new Table({ rows }));
                break;
              case 'h1':
                paragraphs.push(new Paragraph({
                  text: child.textContent,
                  heading: HeadingLevel.HEADING_1
                }));
                break;
              case 'h2':
                paragraphs.push(new Paragraph({
                  text: child.textContent,
                  heading: HeadingLevel.HEADING_2
                }));
                break;
              case 'h3':
                paragraphs.push(new Paragraph({
                  text: child.textContent,
                  heading: HeadingLevel.HEADING_3
                }));
                break;
              case 'h4':
                paragraphs.push(new Paragraph({
                  text: child.textContent,
                  heading: HeadingLevel.HEADING_4
                }));
                break;
              default:
                children.push(new TextRun(child.textContent));
            }
          }
        });
        return children;
      };
  
      traverseNodes(body);
  
      return paragraphs;
    } catch (error) {
      console.error('Error converting HTML to Word:', error);
      throw error;
    }
  };

  
  const handleDownload = async (report, format) => {
      setIsLoading(true);
      try {
        let content, filename;
        if (format === 'word') {
          const content = report.summary; // This should be the HTML content
          console.log('Content:', content);
          const filename = `${report.title}.docx`;
    
          // Parse the HTML content
          const docxElements = await convertHtmlToDocx(content);
    
          // Create a new Document
          const doc = new Document({
            sections: [{
              properties: {},
              children: docxElements, // This should now be an array of Paragraph objects
            }],
          });
    
          // Generate the document as a Blob
          const blob = await Packer.toBlob(doc);
    
          // Verify blob size and content
          if (blob.size === 0) {
            throw new Error('Generated document is empty');
          }
    
          // Create a URL for the blob and trigger download
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
    
          message.success('Word document downloaded successfully');
        }else if (format === 'excel') {
          filename = `${report.title}.xlsx`;
          const workbook = await htmlToExcel(report.summary);
          XLSX.writeFile(workbook, filename);
        }
    
        message.success(`${format.charAt(0).toUpperCase() + format.slice(1)} document generated successfully`);
      } catch (error) {
        console.error(`Error generating ${format} file:`, error);
        message.error(`Failed to generate ${format} file: ${error.message}`);
      } finally {
        setIsLoading(false);
        setReportToExport(null);
        setExportFormat('word');
      }
    };
  
   



  const htmlToExcel = async (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const tables = doc.querySelectorAll('table');
    
    const workbook = XLSX.utils.book_new();
    
    tables.forEach((table, index) => {
      const sheet = XLSX.utils.table_to_sheet(table);
      XLSX.utils.book_append_sheet(workbook, sheet, `Table ${index + 1}`);
      
      // Apply some basic styling
      const range = XLSX.utils.decode_range(sheet['!ref']);
      for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellRef = XLSX.utils.encode_cell({r: R, c: C});
          if (!sheet[cellRef]) continue;
          
          sheet[cellRef].s = {
            font: { name: "Arial", sz: 11 },
            alignment: { vertical: "center", horizontal: "left", wrapText: true }
          };
          
          // Style header row
          if (R === range.s.r) {
            sheet[cellRef].s.font.bold = true;
            sheet[cellRef].s.fill = { fgColor: { rgb: "EEEEEE" } };
          }
        }
      }
      
      // Auto-size columns
      const cols = [];
      for (let C = range.s.c; C <= range.e.c; ++C) {
        cols.push({ wch: 15 }); // Set a default width
      }
      sheet['!cols'] = cols;
    });
    
    // If no tables, create a sheet with general content
    if (tables.length === 0) {
      const content = doc.body.textContent;
      const sheet = XLSX.utils.aoa_to_sheet([[content]]);
      XLSX.utils.book_append_sheet(workbook, sheet, "Content");
    }
    
    return workbook;
  };


  const handleTemplateSelect = (template) => {
    // console.log('Template selected:', template);
    setSelectedTemplate(template);
    setIsTemplateModalVisible(false);
    setIsCustomizeModalVisible(true);
  };

  const handleCustomizeTemplate = async () => {
    if (!selectedReport) {
      message.error('Please select a report first');
      return;
    }
    setIsCustomizeModalVisible(false);
    setIsLoading(true);
    try {
      let updatedContent;
      if (selectedFormat === 'Table') {
        updatedContent = await convertToTable(selectedReport);

      } else if (selectedFormat === 'word') {
        updatedContent = await convertToWord(selectedReport);

      }

      const updatedReport = { ...selectedReport, summary: updatedContent };
      setSelectedReport(updatedReport);
      updateReport(updatedReport);

      if (editorRef.current) {
        editorRef.current.setContents(updatedContent);
      }

      message.success('Report content updated with selected template');
    } catch (error) {
      message.error(`Failed to convert template to ${selectedFormat} format`);
      console.error(`Error converting template to ${selectedFormat}:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Spin spinning={isLoading} tip="Processing...">
      <div className="reports-container">
        <div className="report-list">
          <List
            dataSource={reports}
            renderItem={(report, index) => (
              <List.Item
                actions={[
                  <Button icon={<SwapOutlined />} onClick={() => handleTemplateSelect(report)} />,
                  <Button icon={<DownloadOutlined />} onClick={() => handleExportClick(report)} />,
                  <Popconfirm
                    title="Are you sure you want to delete this report?"
                    onConfirm={() => handleDelete(report.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button icon={<DeleteOutlined />} />
                  </Popconfirm>,
                ]}
              >
                <List.Item.Meta
                  title={<a onClick={() => handleReportClick(report)}>{index + 1}. {report.title}</a>}
                />
              </List.Item>
            )}
          />
        </div>
        <div className="report-details">
          {selectedReport && (
            <div className="report-details-content">
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {isEditingTitle ? (
                    <Input
                      value={editedTitle}
                      onChange={handleTitleChange}
                      onBlur={handleTitleSave}
                      onPressEnter={handleTitleSave}
                      style={{ marginRight: '8px' }}
                    />
                  ) : (
                    <h2 style={{ margin: 0 }}>{editedTitle}</h2>
                  )}
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => setIsEditingTitle(true)}
                    style={{ marginLeft: '8px' }}
                  />
                </div>
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={handleSaveReport}
                >
                  Save
                </Button>
              </div>
              <div className="suneditor-container">
                <SunEditor
                  setContents={ensureString(selectedReport?.summary)}
                  onChange={handleContentChange}
                  setOptions={sunEditorOptions}
                  getSunEditorInstance={(sunEditor) => {
                    editorRef.current = sunEditor;
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <Modal
          title="Customize Template"
          visible={isCustomizeModalVisible}
          onCancel={() => setIsCustomizeModalVisible(false)}
          onOk={handleCustomizeTemplate}
        >
          <Select defaultValue="Table" style={{ width: '100%' }} onChange={value => setSelectedFormat(value)}>
            <Option value="Table">Table</Option>
            <Option value="word">Memo</Option>
          </Select>
        </Modal>

        <Modal
          title="Export Report"
          visible={isExportModalVisible}
          onCancel={handleExportCancel}
          onOk={handleExportOk}
        >
          <Select defaultValue="word" style={{ width: '100%' }} onChange={value => setExportFormat(value)}>
            <Option value="word">Word Document</Option>
            <Option value="excel">Excel</Option>
          </Select>
        </Modal>
      </div>
    </Spin>
  );
};

export default Reports;