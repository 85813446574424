import React, {  useState, useMemo } from 'react';
import { Card } from 'antd';
import ChatMainArea from './ChatMainArea';
import ContextSider from './ContextSider';
import PdfSider from './PdfSider';
import { useDatabase } from '../contexts/DatabaseContext';
import '../assets/styles/chat.css';
import Settings from '../components/layout/Settings';

const Chat = ({ chatId }) => {
  const {
    chatStreams,
    sendMessage,
    query,
    handleQuery,
    pdfUrl,
    searchResults,
    setSearchResults,
    handlePdfRetrieval,
  } = useDatabase();
  const [pdfPage, setPdfPage] = useState(1);

  // useEffect(() => {
  //   console.log('pdfpage', pdfPage);
  // }, [pdfPage]);

  const matchesInfo = useMemo(() => {
    if (!searchResults || Object.keys(searchResults).length === 0 || !pdfUrl) {
      return { matches: [], pageNumber: null };
    }

    const results = searchResults[chatId] || [];
    
    // Extract the filename without query parameters
    const currentFileName = pdfUrl.split('/').pop().split('?')[0];

    // Find the result object for the current file
    const currentFileResult = results.find(result => result.filename === currentFileName);

    if (!currentFileResult) {
      // console.log('No results found for current file');
      return { matches: [], pageNumber: null };
    }
    const matches= currentFileResult.matches[0].metadata || [];

    return {
      matches
    };
  }, [searchResults, chatId, pdfUrl]);

  return (
    <div className="content-container" style={{ borderRadius: "10px"}}>    

      <Card className="chat-container">
        <div className="search-radius-slider-container">
          <Settings />
        </div>

        <ChatMainArea
          chatId={chatId}
          chatStreams={chatStreams}
          sendMessage={sendMessage}
          query={query}
          handleQuery={handleQuery}
          setSearchResults={setSearchResults}
        />
      </Card>

      <Card className="context-sider" style={{ borderRadius: "10px" }}>
        <ContextSider
          chatId={chatId}
          searchResults={searchResults}
          handlePdfRetrieval={handlePdfRetrieval}
          setPdfPage={setPdfPage}
        />
      </Card>

      <Card className="pdf-sider" style={{ borderRadius: "10px" }}>
        <PdfSider
          pdfUrl={pdfUrl}
          matches={matchesInfo}
          pdfPage={pdfPage} 
          setPdfPage={setPdfPage}
        />
      </Card>
    </div>
  );
};

export default Chat;
