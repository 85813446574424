import React, { useState } from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import '../../assets/styles/auth.css';
import LandingPage from './LandingPage';


const ForgotPassword = () => {
  const { forgotPassword, forgotPasswordSubmit } = useAuth();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [codeSent, setCodeSent] = useState(false);

  const onRequestCode = async (values) => {
    setLoading(true);
    try {
      await forgotPassword(values.username);
      message.success('Verification code sent to your email.');
      setCodeSent(true);
    } catch (error) {
      message.error('Failed to send verification code: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const onResetPassword = async (values) => {
    setLoading(true);
    try {
      await forgotPasswordSubmit(values.username, values.code, values.newPassword);
      message.success('Password reset successfully. You can now log in with your new password.');
      form.resetFields();
      setCodeSent(false);
    } catch (error) {
      message.error('Failed to reset password: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LandingPage>
    <div className="auth-container">
      <Card title="Forgot Password" className="auth-card">
        <Form
          form={form}
          name="forgot-password"
          className="auth-form"
          onFinish={codeSent ? onResetPassword : onRequestCode}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Username" />
          </Form.Item>
          {codeSent && (
            <>
              <Form.Item
                name="code"
                rules={[{ required: true, message: 'Please input the verification code!' }]}
              >
                <Input prefix={<LockOutlined />} placeholder="Verification Code" />
              </Form.Item>
              <Form.Item
                name="newPassword"
                rules={[{ required: true, message: 'Please input your new password!' }]}
              >
                <Input.Password prefix={<LockOutlined />} placeholder="New Password" />
              </Form.Item>
            </>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit" className="auth-form-button" loading={loading}>
              {codeSent ? 'Reset Password' : 'Send Verification Code'}
            </Button>
          </Form.Item>
        </Form>
        <div className="auth-links">
          <Link to="/login">Back to Login</Link>
        </div>
      </Card>
    </div>
    </LandingPage>
  );
};

export default ForgotPassword;