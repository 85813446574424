import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Card, Button, Typography, message, Spin, Slider } from 'antd';
import { ZoomInOutlined, ZoomOutOutlined, DragOutlined, RotateLeftOutlined, RotateRightOutlined } from '@ant-design/icons';
import '../assets/styles/pdfsider.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
const { Text } = Typography;

pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.mjs';



const PdfSider = ({ pdfUrl, pdfPage, setPdfPage }) => {
  const [numPages, setNumPages] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [currentPdfPage, setCurrentPdfPage] = useState(pdfPage);
  const [isHandToolActive, setIsHandToolActive] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  useEffect(() => {
    setCurrentPdfPage(pdfPage);
  }, [pdfPage]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsLoading(false);
    // console.log(`Loaded a file with ${numPages} pages`);
  };

  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 3));
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.5));
  };

  const handleRotateLeft = () => {
    setRotation((prevRotation) => prevRotation - 90);
  };

  const handleRotateRight = () => {
    setRotation((prevRotation) => prevRotation + 90);
  };

  const toggleHandTool = () => {
    setIsHandToolActive(!isHandToolActive);
  };

  const handleMouseDown = (e) => {
    if (isHandToolActive) {
      setIsDragging(true);
      setDragStart({ x: e.clientX - position.x, y: e.clientY - position.y });
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging && isHandToolActive) {
      const newX = e.clientX - dragStart.x;
      const newY = e.clientY - dragStart.y;
      setPosition({ x: newX, y: newY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handlePageChange = (direction) => {
    setCurrentPdfPage((prevPage) => {
      const newPage = direction === 'next' ? Math.min(prevPage + 1, numPages) : Math.max(prevPage - 1, 1);
      setPdfPage(newPage); // update the parent component's state
      return newPage;
    });
  };

  return (
    <Card title="PDF Viewer" bordered={false} className="pdf-sider-card">
      <div className={'pdf-sider-content'}>
        <div className="pdf-sider-controls">
          <Button icon={<ZoomOutOutlined />} onClick={handleZoomOut} style={{ marginRight: '10px' }} />
          <Slider
            min={0.5}
            max={3}
            step={0.1}
            value={scale}
            onChange={setScale}
            className="pdf-sider-zoom-slider"
          />
          <Button icon={<ZoomInOutlined />} onClick={handleZoomIn} style={{ marginRight: '10px' }} />
          <span style={{ marginRight: '10px' }}>{Math.round(scale * 100)}%</span>
          <Button icon={<RotateLeftOutlined />} onClick={handleRotateLeft} style={{ marginRight: '10px' }} />
          <Button icon={<RotateRightOutlined />} onClick={handleRotateRight} style={{ marginRight: '10px' }} />
          <Button
            icon={<DragOutlined />}
            onClick={toggleHandTool}
            type={isHandToolActive ? 'primary' : 'default'}
          />
        </div>
        <div
          ref={containerRef}
          className={`pdf-sider-container ${isHandToolActive ? 'grab' : ''}`}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          {pdfUrl ? (
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error) => {
                message.error("Failed to load PDF. Please select correct knowldgebase.");
                setIsLoading(false);
              }}
              loading={<Spin tip="Loading PDF..." />}
            >
              <div className="pdf-sider-document" style={{
                transform: `translate(${position.x}px, ${position.y}px) rotate(${rotation}deg)`,
                transition: isDragging ? 'none' : 'transform 0.3s ease-out',
              }}>
                <Page
                  pageNumber={currentPdfPage}
                  scale={scale}
                  renderTextLayer={true}
                  renderAnnotationLayer={true}
                />
              </div>
            </Document>
          ) : (
            <Text>No PDF available</Text>
          )}
        </div>

        <div className="pdf-sider-pagination">
          <Button 
            disabled={currentPdfPage <= 1} 
            onClick={() => handlePageChange('previous')}
          >
            Previous
          </Button>
          <span style={{ margin: '0 10px' }}>Page {currentPdfPage} of {numPages}</span>
          <Button 
            disabled={currentPdfPage >= numPages} 
            onClick={() => handlePageChange('next')}
          >
            Next
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default PdfSider;