import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import '../../assets/styles/auth.css';
import LandingPage from './LandingPage';
const ConfirmSignUp = () => {
  const { confirmSignUp, resendConfirmationCode } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location.state && location.state.email) {
      form.setFieldsValue({ username: location.state.email });
    }
  }, [location, form]);

  const onFinish = async (values) => {
    const { username, code } = values;
    setLoading(true);
    try {
      await confirmSignUp(username, code);
      message.success('Account confirmed successfully!');
      history.push('/login');
    } catch (error) {
      console.error('Confirm signup error:', error);
      message.error('Failed to confirm account. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    const username = form.getFieldValue('username');
    if (!username) {
      message.error('Please enter your username to resend the code.');
      return;
    }

    setLoading(true);
    try {
      await resendConfirmationCode(username);
    } catch (error) {
      console.error('Resend code error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LandingPage>
    <div className="auth-container">
      <Card title="Confirm Sign Up" className="auth-card">
        <Form
          form={form}
          name="confirm-signup"
          className="auth-form"
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="code"
            rules={[{ required: true, message: 'Please input your confirmation code!' }]}
          >
            <Input prefix={<LockOutlined />} placeholder="Confirmation Code" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="auth-form-button" loading={loading}>
              Confirm Account
            </Button>
          </Form.Item>
          <Form.Item>
            <Button onClick={handleResendCode} className="auth-form-button" loading={loading}>
              Resend Confirmation Code
            </Button>
          </Form.Item>
        </Form>
        <div className="auth-links">
          <Link to="/login">Back to Login</Link>
        </div>
      </Card>
    </div>
    </LandingPage>
  );
};

export default ConfirmSignUp;