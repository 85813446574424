import React, { useState } from "react";
import { Layout, Button, Breadcrumb, Dropdown, Menu, Avatar, Typography, Modal, Form, Input, message } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  UserOutlined,
  DollarOutlined,
  CrownOutlined
} from "@ant-design/icons";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { useAuth } from '../../hooks/useAuth';
import ProfileModal from "./Profile";
import BillingModal from "./Billing";
import PlanModal from "./Plan";
import "../../assets/styles/header.css";

const { Header: AntHeader } = Layout;
const { Text } = Typography;

function Header({ collapsed, toggleCollapsed, fixed }) {
  const { pathname } = useLocation();
  const history = useHistory();
  const { user, signOut } = useAuth(); // Removed signIn
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const [billingModalVisible, setBillingModalVisible] = useState(false);
  const [planModalVisible, setPlanModalVisible] = useState(false);
  const [loginModalVisible, setLoginModalVisible] = useState(false);

  const [form] = Form.useForm();

  const formattedPathname = pathname.replace(/^\//, '').charAt(0).toUpperCase() + pathname.replace(/^\//, '').slice(1);

  const handleLogout = async () => {
    try {
      await signOut(); // Call signOut from useAuth
      history.push('/login');
    } catch (error) {
      console.error('Error signing out:', error);
      message.error('Failed to sign out. Please try again.');
    }
  };

  const handleLogin = async (values) => {
    try {
      // Handle login if needed or remove this function if not used
      // await signIn(values.username, values.password);
      setLoginModalVisible(false);
      form.resetFields();
      message.success('Successfully logged in!');
    } catch (error) {
      console.error('Login failed:', error);
      message.error('Login failed. Please check your credentials and try again.');
    }
  };

  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : 'U';
  };

  const menu = (
    <Menu>
      <Menu.Item key="user-info" disabled style={{ cursor: 'default' }}>
        <div>
          <Text strong>{user?.attributes?.name || user?.username}</Text>
          <br />
          <Text type="secondary">{user?.attributes?.email}</Text>
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="profile" icon={<UserOutlined />} onClick={() => setProfileModalVisible(true)}>
        Profile
      </Menu.Item>
      <Menu.Item key="billing" icon={<DollarOutlined />} onClick={() => setBillingModalVisible(true)}>
        Billing
      </Menu.Item>
      <Menu.Item key="plan" icon={<CrownOutlined />} onClick={() => setPlanModalVisible(true)}>
        Plan
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <AntHeader className={`site-header ${fixed ? "ant-header-fixed" : ""}`}>
        <div className="header-container">
          <div className="header-left">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item>
                <NavLink to="/">Pages</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{formattedPathname}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="header-right">
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={toggleCollapsed}
              className="menu-button"
            />
            {!user ? (
              <Button onClick={() => setLoginModalVisible(true)} className="auth-button">
                Login
              </Button>
            ) : (
              <Dropdown overlay={menu} placement="bottomRight" arrow trigger={['click']}>
                <Avatar 
                  className="profile-avatar"
                  style={{ 
                    backgroundColor: 'purple', 
                    color: '#ffffff',
                    cursor: 'pointer'
                  }}
                >
                  {getInitial(user?.attributes?.name || user?.username)}
                </Avatar>
              </Dropdown>
            )}
          </div>
        </div>
      </AntHeader>
      
      {/* Login Modal */}
      <Modal
        title="Login"
        visible={loginModalVisible}
        onCancel={() => setLoginModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleLogin}>
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <ProfileModal
        visible={profileModalVisible}
        onCancel={() => setProfileModalVisible(false)}
        user={user}
      />
      <BillingModal
        visible={billingModalVisible}
        onCancel={() => setBillingModalVisible(false)}
      />
      <PlanModal
        visible={planModalVisible}
        onCancel={() => setPlanModalVisible(false)}
      />
    </>
  );
}

export default Header;
