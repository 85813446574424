import React, { createContext, useState, useContext, useEffect } from 'react';
import { sendMessageToOpenAI } from '../services/openaiService';
import { documentService } from '../services/documentService';

const DatabaseContext = createContext();

export const DatabaseProvider = ({ children }) => {
  const [chatStreams, setChatStreams] = useState({});
  const [query, setQuery] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');
  const [contextHistory, setContextHistory] = useState({});
  const [chatNames, setChatNames] = useState({});
  const [reports, setReports] = useState([]);
  const [searchRadius, setSearchRadius] = useState(5);
  const [pdfState, setPdfState] = useState({});
  const [localPdfs, setLocalPdfs] = useState({});
  const [searchResults, setSearchResults] = useState({});
  const [documents, setDocuments] = useState([]);
  const [vectorStores, setVectorStores] = useState([]);
  const [selectedVectorStore, setSelectedVectorStore] = useState(null);

  useEffect(() => {
    const loadedChatStreams = JSON.parse(localStorage.getItem('chatStreams')) || {};
    const loadedContextHistory = JSON.parse(localStorage.getItem('contextHistory')) || {};
    const loadedChatNames = JSON.parse(localStorage.getItem('chatNames')) || {};
    const loadedReports = JSON.parse(localStorage.getItem('reports')) || [];
    const loadedSearchRadius = JSON.parse(localStorage.getItem('searchRadius')) || 5;
    const loadedPdfState = JSON.parse(localStorage.getItem('pdfState')) || {};
    const loadedLocalPdfs = JSON.parse(localStorage.getItem('localPdfs')) || {};
    const loadedSearchResults = JSON.parse(localStorage.getItem('searchResults')) || {};
    const loadedDocuments = JSON.parse(localStorage.getItem('documents')) || [];
    const loadedSelectedVectorStore = localStorage.getItem('selectedVectorStore') || null;

    setChatStreams(loadedChatStreams);
    setContextHistory(loadedContextHistory);
    setChatNames(loadedChatNames);
    setReports(loadedReports);
    setSearchRadius(loadedSearchRadius);
    setPdfState(loadedPdfState);
    setLocalPdfs(loadedLocalPdfs);
    setSearchResults(loadedSearchResults);
    setDocuments(loadedDocuments);
    setSelectedVectorStore(loadedSelectedVectorStore);

    // Fetch vector stores from the API
    const fetchVectorStores = async () => {
      try {
        const stores = await documentService.getVectorStores();
        setVectorStores(stores);
      } catch (error) {
        console.error('Error fetching vector stores:', error);
      }
    };

    fetchVectorStores();
  }, []);

  useEffect(() => {
    localStorage.setItem('chatStreams', JSON.stringify(chatStreams));
    localStorage.setItem('contextHistory', JSON.stringify(contextHistory));
    localStorage.setItem('chatNames', JSON.stringify(chatNames));
    localStorage.setItem('reports', JSON.stringify(reports));
    localStorage.setItem('searchRadius', JSON.stringify(searchRadius));
    localStorage.setItem('pdfState', JSON.stringify(pdfState));
    localStorage.setItem('localPdfs', JSON.stringify(localPdfs));
    localStorage.setItem('searchResults', JSON.stringify(searchResults));
    localStorage.setItem('documents', JSON.stringify(documents));
    localStorage.setItem('selectedVectorStore', selectedVectorStore);
  }, [chatStreams, contextHistory, chatNames, reports, searchRadius, pdfState, localPdfs, searchResults, documents, selectedVectorStore]);

  const handleQuery = (newQuery) => {
    setQuery(newQuery);
  };

  const generateUniqueId = () => '_' + Math.random().toString(36).substr(2, 9);

  const sendMessage = async (message, chatId) => {
    const newMessage = {
      id: generateUniqueId(),
      type: 'user',
      content: message,
    };

    setChatStreams(prev => {
      const chatMessages = prev[chatId] || [];
      const newMessages = [...chatMessages, newMessage];

      if (chatMessages.length === 0) {
        setChatNames(prevNames => ({
          ...prevNames,
          [chatId]: message.length > 20 ? message.substring(0, 20) + '...' : message
        }));
      }

      return { ...prev, [chatId]: newMessages };
    });

    setContextHistory(prev => ({
      ...prev,
      [chatId]: [...(prev[chatId] || []), message]
    }));

    try {
      const aiResponse = await sendMessageToOpenAI(
        selectedVectorStore,
        message, 
        chatStreams[chatId] || [], 
        searchRadius,
      );

      const { summarized_text, filenames, page_numbers, matches } = aiResponse;

      if (!Array.isArray(filenames) || !Array.isArray(page_numbers) || !Array.isArray(matches) || filenames.length !== page_numbers.length || filenames.length !== matches.length) {
        throw new Error('Invalid results format');
      }

      const results = filenames.map((filename, index) => ({
        filename,
        pageNumber: page_numbers[index],
        match: matches[index]
      }));

      const mergedResults = results.reduce((acc, current) => {
        const { filename, pageNumber, match } = current;
        const key = `${filename}-${pageNumber}`;
        if (!acc[key]) {
          acc[key] = { filename, pageNumber, matches: [] };
        }
        acc[key].matches.push(match);
        return acc;
      }, {});

      const aiMessage = {
        id: generateUniqueId(),
        type: 'ai',
        content: summarized_text,
      };

      setChatStreams(prev => {
        const chatMessages = prev[chatId] || [];
        const newMessages = [...chatMessages, aiMessage];
        return { ...prev, [chatId]: newMessages };
      });

      setSearchResults(prev => ({
        ...prev,
        [chatId]: [...(prev[chatId] || []), ...Object.values(mergedResults)]
      }));

      setQuery('');
    } catch (error) {
      console.error('Error sending message to OpenAI:', error);
      let errorMessage = 'Sorry, I encountered an error.';
      if (error.message.includes('API key')) {
        errorMessage = "There's an issue with the AI service configuration. Please contact support.";
      }

      const errorResponse = {
        id: generateUniqueId(),
        type: 'ai',
        content: errorMessage,
      };

      setChatStreams(prev => {
        const chatMessages = prev[chatId] || [];
        const newMessages = [...chatMessages, errorResponse];
        return { ...prev, [chatId]: newMessages };
      });
    }
  };

  const addNewChat = (chatId) => {
    setChatStreams(prev => ({ ...prev, [chatId]: [] }));
    setContextHistory(prev => ({ ...prev, [chatId]: [] }));
  };

  const deleteChat = (chatId) => {
    setChatStreams(prev => {
      const newChatStreams = { ...prev };
      delete newChatStreams[chatId];
      return newChatStreams;
    });
    setContextHistory(prev => {
      const newContextHistory = { ...prev };
      delete newContextHistory[chatId];
      return newContextHistory;
    });
    setChatNames(prev => {
      const newChatNames = { ...prev };
      delete newChatNames[chatId];
      return newChatNames;
    });
    setPdfState(prev => {
      const newPdfState = { ...prev };
      delete newPdfState[chatId];
      return newPdfState;
    });
    setSearchResults(prev => {
      const newSearchResults = { ...prev };
      delete newSearchResults[chatId];
      return newSearchResults;
    });
  };

  const addReport = (report) => {
    setReports(prev => [...prev, report]);
  };

  const updateReport = (updatedReport) => {
    console.log('Updating report:', updatedReport);
    setReports(prev => prev.map(report => report.id === updatedReport.id ? updatedReport : report));
  };

  const deleteReport = (reportId) => {
    setReports(prev => prev.filter(report => report.id !== reportId));
  };

  const updatePdfState = (filename, pageNumber) => {
    setPdfState(prev => ({
      ...prev,
      [filename]: pageNumber
    }));
  };

  const handlePdfRetrieval = async (organizationId, filename, pageNumber) => {
    console.log('handlePdfRetrieval called with:', { filename, pageNumber });
    try {
      console.log('PDF not found in local storage, retrieving from server');
      const result = await documentService.retrievePdf(organizationId, filename);
      if (!result || !result.pdfUrl) {
        throw new Error('Failed to retrieve PDF from server');
      }

      setPdfUrl(result.pdfUrl);
      updatePdfState(filename, pageNumber);
      return { pdfUrl: result.pdfUrl };
    } catch (error) {
      console.error('Error retrieving PDF:', error);
      throw error;
    }
  };

  const syncKnowledgeBase = async () => {
    try {
      await documentService.syncKnowledgeBase();
      setContextHistory({});
      setSearchResults({});
      // Refresh the vector stores after syncing
      const stores = await documentService.getVectorStores();
      setVectorStores(stores);
    } catch (error) {
      console.error('Error syncing knowledge base:', error);
      throw error;
    }
  };

  return (
    <DatabaseContext.Provider
      value={{
        chatStreams,
        query,
        chatNames,
        handleQuery,
        sendMessage,
        pdfUrl,
        setPdfUrl,
        contextHistory,
        addNewChat,
        deleteChat,
        reports,
        addReport,
        updateReport,
        deleteReport,
        searchRadius,
        setSearchRadius,
        pdfState,
        updatePdfState,
        searchResults,
        setSearchResults,
        handlePdfRetrieval,
        vectorStores,
        selectedVectorStore,
        setSelectedVectorStore,
        syncKnowledgeBase,
      }}
    >
      {children}
    </DatabaseContext.Provider>
  );
};

export const useDatabase = () => {
  const context = useContext(DatabaseContext);
  if (context === undefined) {
    throw new Error('useDatabase must be used within a DatabaseProvider');
  }
  return context;
};

export { DatabaseContext };