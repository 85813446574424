import React, { useState, useEffect, useContext } from 'react';
import { Modal, Form, Input, Button, Layout, Menu, Tabs, message, Table, Popconfirm, DatePicker } from 'antd';
import { UserOutlined, TeamOutlined, ProjectOutlined, LockOutlined, ApiOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import { AuthContext } from '../../contexts/AuthContext';

const { Sider, Content } = Layout;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    overflow: hidden;
  }
`;

const StyledLayout = styled(Layout)`
  height: 600px;
`;

const StyledSider = styled(Sider)`
  background: #f0f2f5;
`;

const StyledContent = styled(Content)`
  padding: 24px;
  background: #fff;
`;

const ProfileModal = ({ visible, onCancel }) => {
  const { user, updateUserAttributes, getUserOrganizationAttributes, validateInviteCode, joinOrganization } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [orgForm] = Form.useForm();
  const [activeMenu, setActiveMenu] = useState('profile');
  const [activeTab, setActiveTab] = useState('details');
  const [loading, setLoading] = useState(false);
  const [organizationDetails, setOrganizationDetails] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [inviteLoading, setInviteLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(user);
      fetchOrgAttributes();
    }
  }, [visible, user, form]);

  const fetchOrgAttributes = async () => {
    try {
      setLoading(true);
      const attributes = await getUserOrganizationAttributes();
      const organizationName = attributes.find(attr => attr.name === 'custom:Organization')?.value || '';
      const role = attributes.find(attr => attr.name === 'custom:Role')?.value || '';
      const joinedTimestamp = attributes.find(attr => attr.name === 'custom:JoinedDate')?.value;
      const joinedDate = joinedTimestamp ? moment.unix(parseInt(joinedTimestamp)).format('YYYY-MM-DD') : '';
      
      setOrganizationDetails(organizationName ? [
        {
          key: '1',
          name: organizationName,
          role: role,
          joined: joinedDate,
        }
      ] : []);

      orgForm.setFieldsValue({ 
        name: organizationName,
        role: role,
        joined: joinedTimestamp ? moment.unix(parseInt(joinedTimestamp)) : null,
      });
    } catch (error) {
      message.error('Failed to fetch organization details.');
      console.error('Error fetching organization attributes:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOk = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      const authorizedAttributes = ['name', 'phone_number', 'custom:Organization'];
      const filteredValues = Object.keys(values)
        .filter(key => authorizedAttributes.includes(key))
        .reduce((obj, key) => {
          obj[key] = values[key];
          return obj;
        }, {});

      await updateUserAttributes(filteredValues);
      message.success('Profile updated successfully');
      onCancel();
    } catch (error) {
      console.error('Update failed:', error);
      message.error('Failed to update profile: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOrgUpdate = async () => {
    try {
      setLoading(true);
      const values = await orgForm.validateFields();
      
      const updateAttributes = {
        'custom:Role': values.role,
        'custom:JoinedDate': values.joined ? values.joined.unix().toString() : '',
      };
  
      await updateUserAttributes(updateAttributes);
      message.success('Organization details updated successfully');
      setEditingKey('');
      await fetchOrgAttributes();
    } catch (error) {
      console.error('Organization update failed:', error);
      message.error(`Failed to update organization details: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };



  const handleJoinOrganization = async () => {
    if (!inviteCode.trim()) {
      message.error('Please enter an invite code');
      return;
    }

    try {
      setInviteLoading(true);
      
      // Validate the invite code
      const validationResult = await validateInviteCode(inviteCode);
      
      if (!validationResult.valid) {
        throw new Error('Invalid invite code');
      }

      // Join the organization
      await joinOrganization(inviteCode);
      console.log('Joined organization successfully');
      setInviteCode('');
      await fetchOrgAttributes(); // Refresh organization details
    } catch (error) {
      console.error('Failed to join organization:', error);
      message.error(`Failed to join organization: ${error.message}`);
    } finally {
      setInviteLoading(false);
    }
  };
  
  const handleLeaveOrganization = async () => {
    try {
      setLoading(true);
      await updateUserAttributes({
        'custom:Organization': '',
        'custom:Role': '',
        'custom:JoinedDate': '',
      });
      message.success('Left organization successfully');
      await fetchOrgAttributes();
    } catch (error) {
      console.error('Failed to leave organization:', error);
      message.error('Failed to leave organization: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    orgForm.setFieldsValue({ 
      ...record, 
      joined: record.joined ? moment(record.joined, 'YYYY-MM-DD') : null 
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };
  const organizationColumns = [
    {
      title: 'Organization Name',
      dataIndex: 'name',
      key: 'name',
      editable: false,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      editable: true,
    },
    {
      title: 'Joined Date',
      dataIndex: 'joined',
      key: 'joined',
      editable: true,
      render: (text) => text || 'N/A',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button type="link" onClick={() => handleOrgUpdate()} style={{ marginRight: 8 }}>
              Save
            </Button>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <span>

            <Popconfirm title="Are you sure you want to leave this organization?" onConfirm={handleLeaveOrganization}>
              <Button type="link" disabled={editingKey !== ''}>
                Leave
              </Button>
            </Popconfirm>
          </span>
        );
      },
    },
  ];


  const mergedColumns = organizationColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'joined' ? 'date' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const projectColumns = [
    {
      title: 'Project Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  const tabItems = [
    {
      key: 'details',
      label: 'Your details',
      children: (
        <Form form={form} layout="vertical" initialValues={user}>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input your name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email address" rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}>
            <Input disabled />
          </Form.Item>
          <Form.Item name="phone_number" label="Phone number">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleOk} loading={loading}>Save</Button>
          </Form.Item>
        </Form>
      )
    },
    {
      key: 'security',
      label: (
        <>
          <LockOutlined />
          Security
        </>
      ),
      children: <p>Security settings content</p>
    },
    {
      key: 'api',
      label: (
        <>
          <ApiOutlined />
          API Keys
        </>
      ),
      children: <p>API keys management content</p>
    }
  ];

  const menuItems = [
    { key: 'profile', icon: <UserOutlined />, label: 'Your profile' },
    { key: 'organization', icon: <TeamOutlined />, label: 'Organization' },
    { key: 'project', icon: <ProjectOutlined />, label: 'Project' },
  ];

  const renderOrganizationContent = () => (
    <div>
      <h2 style={{ marginBottom: '24px' }}>Organization</h2>
      {organizationDetails.length > 0 ? (
        <Form form={orgForm} layout="vertical">
          <Table
            components={{
              body: {
                cell: ({ editing, dataIndex, title, inputType, children, ...restProps }) => {
                  const inputNode = inputType === 'date' ? <DatePicker format="YYYY-MM-DD" /> : <Input />;
                  return (
                    <td {...restProps}>
                      {editing ? (
                        <Form.Item
                          name={dataIndex}
                          style={{ margin: 0 }}
                          rules={[{ required: true, message: `Please Input ${title}!` }]}
                        >
                          {inputNode}
                        </Form.Item>
                      ) : (
                        children
                      )}
                    </td>
                  );
                },
              },
            }}
            bordered
            dataSource={organizationDetails}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      ) : (
        <div>
          <p>You are not part of any organization. Join one using an invite code:</p>
          <Input.Group compact>
            <Input
              style={{ width: 'calc(100% - 200px)' }}
              placeholder="Enter invite code"
              value={inviteCode}
              onChange={(e) => setInviteCode(e.target.value)}
            />
            <Button
              type="primary"
              onClick={handleJoinOrganization}
              loading={inviteLoading}
            >
              Join Organization
            </Button>
          </Input.Group>
        </div>
      )}
    </div>
  );

  return (
    <StyledModal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={1000}
      bodyStyle={{ padding: 0 }}
    >
      <StyledLayout>
        <StyledSider width={200}>
          <div style={{ padding: '16px', fontWeight: 'bold' }}>SETTINGS</div>
          <Menu
            mode="inline"
            defaultSelectedKeys={['profile']}
            style={{ height: '100%', borderRight: 0 }}
            items={menuItems}
            onClick={(e) => setActiveMenu(e.key)}
          />
        </StyledSider>
        <StyledContent>
          {activeMenu === 'profile' && (
            <>
              <h2 style={{ marginBottom: '24px' }}>Your profile</h2>
              <Tabs activeKey={activeTab} onChange={setActiveTab} items={tabItems} />
            </>
          )}
          {activeMenu === 'organization' && renderOrganizationContent()}
          {activeMenu === 'project' && (
           <>
            <h2 style={{ marginBottom: '24px' }}>Projects</h2>
            <Table columns={projectColumns} dataSource={[]} />
           </> 
          )}
        </StyledContent>
      </StyledLayout>
    </StyledModal>
  );
};

export default ProfileModal;