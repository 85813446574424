import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Affix, Drawer } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import Footer from "./Footer";
import "../../assets/styles/main.css";
import useViewport from "../../hooks/useViewport";
import { useAuth } from '../../hooks/useAuth';

const { Content } = Layout;

function Main({ children, chatPaths, addNewChat, deleteChat }) {
  const [collapsed, setCollapsed] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [sidenavColor] = useState("#1890ff");
  const [sidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const { pathname } = useLocation();
  const { width } = useViewport();
  const { user } = useAuth();

  useEffect(() => {
    const handleResize = () => {
      if (width < 768) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    handleResize();
  }, [width]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    setDrawerVisible(!drawerVisible);
  };

  const getDrawerWidth = () => {
    return width < 576 ? '50vw'  // Mobile
         : width < 768 ? '60vw'  // Tablet
         : width < 992 ? '40vw'  // Small Desktop
         : '20vw';               // Desktop
  };
  
  return (
    <Layout className={`layout-dashboard ${pathname === "profile" ? "layout-profile" : ""}`}>
      <Drawer
        placement="left"
        closable={false}
        onClose={toggleCollapsed}
        visible={drawerVisible}
        bodyStyle={{ padding: 0 }}
        drawerStyle={{ background: sidenavType }}
        width={getDrawerWidth()}
      >
        <Sidenav color={sidenavColor} chatPaths={chatPaths} addNewChat={addNewChat} deleteChat={deleteChat} />
      </Drawer>
      <Layout className="site-layout">
        <Affix offsetTop={0}>
          <Header 
            collapsed={collapsed} 
            toggleCollapsed={toggleCollapsed} 
            fixed={fixed} 
            user={user}
          />
        </Affix>
        <Content className="site-content">
          {children}
        </Content>
        <Affix offsetBottom={0}>
          <Footer />
        </Affix>
      </Layout>
    </Layout>
  );
}

export default Main;