// src/config/cognitoConfig.js
const cognitoConfig = {
  UserPoolId: 'us-east-1_6if60QREm',
  ClientId: '7gs0s4bfnngdjif6o0gg4kq268',
  Region: 'us-east-1',
  IdentityPoolId: 'us-east-1:d14b75db-90c9-4b30-bd44-6db5446c3900', 
  GoogleClientId: '891720427979-11s60ejdtpn11br4k0bnvlrdms5anelr.apps.googleusercontent.com',
  redirectUri: 'https://dev.d3ot31e87hqbhv.amplifyapp.com/auth/callback', 
  //  redirectUri: 'localhost:3000/auth/callback', 
};


export { cognitoConfig };