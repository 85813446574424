import React, { useState, useEffect } from 'react';
import { Modal, Typography, List, Button, Spin, message } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { fetchBillingInfo, updatePaymentMethod, getInvoices, getSubscriptionDetails } from '../../services/billlingSerivce';

const { Title, Text } = Typography;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
  }
`;

const StyledList = styled(List)`
  .ant-list-item {
    display: flex;
    justify-content: space-between;
  }
`;

const BillingModal = ({ visible, onCancel, customerId }) => {
  const [billingInfo, setBillingInfo] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchBilling = async () => {
    if (!customerId) {
      message.error('Customer ID is not available');
      return;
    }

    setLoading(true);
    try {
      const info = await fetchBillingInfo(customerId);
      setBillingInfo(info);
      
      const invoicesData = await getInvoices(customerId);
      setInvoices(invoicesData.data);

      if (info.subscriptions.data.length > 0) {
        const subDetails = await getSubscriptionDetails(info.subscriptions.data[0].id);
        setSubscription(subDetails);
      }
    } catch (error) {
      console.error('Error fetching billing info:', error);
      message.error('Failed to fetch billing information');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible && customerId) {
      fetchBilling();
    }
  }, [visible, customerId]);

  const handleUpdatePayment = async () => {
    // Implementation for updating payment method
  };

  return (
    <StyledModal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      title="Billing Information"
      width={600}
    >
      {loading ? (
        <Spin size="large" />
      ) : billingInfo ? (
        <>
          <Title level={4}>Customer Details</Title>
          <Text>Name: {billingInfo.name}</Text>
          <br />
          <Text>Email: {billingInfo.email}</Text>

          {subscription && (
            <>
              <Title level={4} style={{ marginTop: '20px' }}>Current Plan: {subscription.plan.nickname}</Title>
              <Text>Amount: ${subscription.plan.amount / 100} / {subscription.plan.interval}</Text>
              <br />
              <Text>Next billing date: {new Date(subscription.current_period_end * 1000).toLocaleDateString()}</Text>
            </>
          )}

          <StyledList
            style={{ marginTop: '20px' }}
            header={<Title level={5}>Billing History</Title>}
            bordered
            dataSource={invoices}
            renderItem={invoice => (
              <List.Item>
                <Text>{new Date(invoice.created * 1000).toLocaleDateString()}</Text>
                <Text strong>${invoice.amount_paid / 100}</Text>
              </List.Item>
            )}
          />
          <Button 
            type="primary" 
            icon={<CreditCardOutlined />} 
            style={{ marginTop: '20px' }}
            onClick={handleUpdatePayment}
          >
            Update Payment Method
          </Button>
        </>
      ) : (
        <Text>No billing information available</Text>
      )}
    </StyledModal>
  );
};

export default BillingModal;