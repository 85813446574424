import React, { useState } from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import '../../assets/styles/auth.css';
import LandingPage from './LandingPage';

const ForgotUsername = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      // This is a placeholder. You'll need to implement the actual functionality
      // to send the username to the user's email address.
      console.log('Sending username reminder to:', values.email);
      message.success('If an account exists with this email, a username reminder will be sent.');
      form.resetFields();
    } catch (error) {
      message.error('Failed to process request: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LandingPage>
    <div className="auth-container">
      <Card title="Forgot Username" className="auth-card">
        <Form
          form={form}
          name="forgot-username"
          className="auth-form"
          onFinish={onSubmit}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please enter a valid email address!' }
            ]}
          >
            <Input prefix={<MailOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="auth-form-button" loading={loading}>
              Send Username Reminder
            </Button>
          </Form.Item>
        </Form>
        <div className="auth-links">
          <Link to="/login">Back to Login</Link>
        </div>
      </Card>
    </div>
    </LandingPage>
  );
};

export default ForgotUsername;