import React from 'react';
import { Button, Card, Layout, Typography, Row, Col, List, Space } from 'antd';
import { MessageOutlined, FileTextOutlined, SettingOutlined, DownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
const { Header, Content } = Layout;
const { Title, Paragraph, Text } = Typography;

const FeatureCard = ({ icon, title, description }) => (
  <Card
    hoverable
    style={{ height: '100%', marginBottom: '1rem' }}
    bodyStyle={{ padding: '12px' }}
  >
    <Row gutter={[8, 0]} align="middle">
      <Col>{React.cloneElement(icon, { style: { fontSize: '24px', color: '#1890ff' } })}</Col>
      <Col flex="auto">
        <Text strong>{title}</Text>
        <Paragraph type="secondary" style={{ fontSize: '12px', marginBottom: 0 }}>{description}</Paragraph>
      </Col>
    </Row>
  </Card>
);

const LandingPage = ({ children }) => {
  return (
    <Layout style={{ minHeight: '100vh', margin: 0 }}>
      <Header style={{ background: '#fff', borderBottom: '1px solid #f0f0f0', padding: '0 2rem' }}>
        <Row justify="space-between" align="middle" style={{ height: '100%' }}>
          <Col>
            <Title level={3} style={{ color: '#1890ff', margin: 0 }}>DocuSearch</Title>
          </Col>
          <Col>
            <Space>
            <Button type="default" style={{ marginLeft: '8px' }}>
              <Link to="/signup">Sign Up</Link>
            </Button>
              <Button type="primary">
                <Link to="/login">Login</Link>
              </Button>
            </Space>
          </Col>
        </Row>
      </Header>
      <Content style={{ padding: 0, margin: 0, overflow: 'hidden' }}>
        <Row gutter={0} style={{ height: 'calc(100vh - 64px)', margin: 0 }}>
          <Col span={12} style={{ padding: '8rem', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Title style={{ marginBottom: '1rem', fontSize: '2.5rem' }}>Revolutionize Document Interaction with AI</Title>
              <Paragraph style={{ fontSize: '16px', marginBottom: '2rem' }}>
                DocuSearch combines RAG technology and AI to transform how you interact with and extract insights from your documents.
              </Paragraph>

              <Row gutter={[16, 16]} style={{ marginBottom: '2rem' }}>
                <Col span={12}>
                  <FeatureCard
                    icon={<MessageOutlined />}
                    title="Intelligent Chat"
                    description="Chat with your documents like never before."
                  />
                </Col>
                <Col span={12}>
                  <FeatureCard
                    icon={<FileTextOutlined />}
                    title="Context-Aware Results"
                    description="View relevant context for AI responses."
                  />
                </Col>
                <Col span={12}>
                  <FeatureCard
                    icon={<DownloadOutlined />}
                    title="Automated Reporting"
                    description="Generate AI-powered reports and memos."
                  />
                </Col>
                <Col span={12}>
                  <FeatureCard
                    icon={<SettingOutlined />}
                    title="Customizable Settings"
                    description="Tailor the AI to your specific needs."
                  />
                </Col>
              </Row>

              <Title level={4} style={{ marginBottom: '1rem' }}>Why Choose DocuSearch?</Title>
              <List
                size="small"
                split={false}
                dataSource={[
                  "Chat with thousands of pages documents effortlessly",
                  "Customizable result settings for precise queries",
                  "AI-organized responses with relevant context",
                  "Seamless PDF viewing and exploration"
                ]}
                renderItem={item => (
                  <List.Item>
                    <Text>• {item}</Text>
                  </List.Item>
                )}
                style={{ marginBottom: '2rem' }}
              />
              <Paragraph style={{ fontSize: '16px', marginBottom: '1rem' }}>
                Ready to transform your document interactions? Email us at <a href="mailto:info@urbanlytics.ca">info@urbanlytics.ca</a>
              </Paragraph>
            </div>
          </Col>
          <Col span={12} style={{ height: '100%' }}>
            {children}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default LandingPage;
