import axios from 'axios';
import { OPENAI_API_URL } from '../config/openai';
import { getAuthHeader } from '../utils/apiUtils';
import { cognitoService } from './cognitoService';

// Replace this with your actual API Gateway URL

const VECTOR_SEARCH_URL = 'https://ysqvph5woa.execute-api.us-east-1.amazonaws.com/preProd/vectorsearch';

export const sendMessageToOpenAI = async (index_name, message, previous_history, search_radius) => {

  // console.log("index_name: ", index_name);
  const data = {
    index_name: index_name,
    query: message,
    previous_history: previous_history,
    search_radius: search_radius
  };

  // console.log('Request URL:', VECTOR_SEARCH_URL);
  // console.log('Request Body:', JSON.stringify(data));

  try {
    // Get the authentication token
    const session = await cognitoService.getSession();
    const token = session.getIdToken().getJwtToken();

    // Make the authenticated request to the vector search URL
    const response = await axios({
      method: 'POST',
      url: VECTOR_SEARCH_URL,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: data
    });

    // console.log('Response Status:', response.status);
    // console.log('Response Body:', response.data);

    if (response.status !== 200) {
      console.error('Error response:', response.data);
      throw new Error(`HTTP error! status: ${response.status}, message: ${JSON.stringify(response.data)}`);
    }

    return response.data;
  } catch (error) {
    console.error('Error calling AWS API:', error);
    throw error;
  }
};


export const organizeContent = async (content) => {
  try {
    const response = await axios.post(
      OPENAI_API_URL,
      {
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: "You are a helpful assistant that organizes content into detailed and well-structured reports."
          },
          {
            role: "system",
            content: "Generate a comprehensive report in proper HTML format with necessary tags for formatting. Ensure the report includes a clear title, hierarchical numbered headings (e.g., 1.0, 1.1, 1.1.1), paragraphs, and bullet points where appropriate. Organize the content logically, grouping related information together, and use HTML tags such as <h1>, <h2>, <h3>, <p>, <ul>, and <li> to format the report. Do not summarize or lose any details; only organize and format the content."
          },
          {
            role: "user",
            content: `Organize the following contents into one continuous report, adding appropriate numbering for headings and subheadings, and ensuring all content is well-formatted: ${content}`
          }
        ]
      },
      { headers: getAuthHeader() }
    );
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error organizing content:', error);
    return '<p>Error generating report</p>';
  }
};

export const convertToTable = async (report) => {
  try {
    if (!Array.isArray(report.original) || report.original.length === 0) {
      throw new Error('Invalid report structure: original content is missing or empty');
    }

    // Get all content from report.original
    const originalContent = report.original.join('\n');

    const response = await axios.post(
      OPENAI_API_URL,
      {
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: "You are a helpful assistant that converts report data to a comprehensive HTML table format for Excel export. The table must have at least 2 columns."
          },
          {
            role: "user",
            content: `Convert the following report data into an HTML table. Follow these guidelines:
              1. Use <table>, <thead>, <tbody>, <tr>, <th>, and <td> tags.
              2. The table must have at least 2 columns.
              3. Use <th> tags in <thead> for column headers.
              4. Each <tr> in <tbody> should represent one data item.
              5. If the data doesn't naturally fit into multiple columns, create a 'Category' column and a 'Details' column.
              6. Ensure no row has only one <td> element.
              7. Determine the most logical multi-column structure based on the data provided.
              8. If the original content is already in HTML format, extract the relevant information and reorganize it into a table format.
              9. Preserve all important information from the original content.

      
              Here's the report data: ${JSON.stringify(originalContent)}
            `
          }
        ]
      },
      { headers: getAuthHeader() }
    );

    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error converting report to Table:', error);
    throw error;
  }
};

export const convertToWord = async (report) => {
  try {
    if (!Array.isArray(report.original) || report.original.length === 0) {
      throw new Error('Invalid report structure: original content is missing or empty');
    }

    // Get all content from report.original
    const originalContent = report.original.join('\n');

    const response = await axios.post(
      OPENAI_API_URL,
      {
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: "You are a skilled technical writer that formats report data into a comprehensive and well-structured tech memo, focusing on key findings and a concise conclusion. You will generate valid HTML content that can be displayed in an HTML editor."
          },
          {
            role: "user",
            content: `Format the following report data following these guidelines strictly:
              1. Make sure the doctype is html and the content is wrapped in <html>, <head>, and <body> tags.
              2. Structure the memo as follows:
                 a. <h1>Technical Memo</h1>
                 b. <h2>Date:</h2> (use the date from the content if available, otherwise use the current date)
                 c. <h2>Subject:</h2> (use the subject from the content)
                 d. <h2>Introduction</h2> (use the content from the <intro> tag if available, or create a brief introduction based on the overall content)
                 e. <h2>Key Findings</h2>
                    - Include ALL content from the <detailed_answer> section here
                    - Use <h3> tags for main subsections
                    - Use <p> tags for paragraphs and <ul> and <li> tags for bullet points
                    - Ensure all information from the original content is included, even if it was originally in a different section
                    - Present the information as key takeaways or important findings
                 f. <h2>Conclusion</h2>
                    - Provide a brief, concise summary of the main points (2-3 sentences maximum)
                    - Focus on the most critical takeaways or action items
              3. Do not include a separate Sources section. If sources were mentioned in the original content, integrate them into the relevant Key Findings subsections.
              4. Ensure that all content from the original data is included somewhere in the memo.
              5. Preserve the exact wording of technical specifications and requirements.
              6. If any content doesn't fit clearly into the above sections, include it in the most appropriate section, erring on the side of inclusion rather than omission.
              7. Use appropriate HTML tags for formatting, such as <strong> for emphasis and <em> for italics when necessary.
              8. Ensure all HTML tags are properly closed and nested correctly.

              Here's the report data: ${JSON.stringify(originalContent)}
            `
          }
        ]
      },
      { headers: getAuthHeader() }
    );

    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error converting report to Word:', error);
    throw error;
  }
};