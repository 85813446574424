import React, { useState } from "react";
import { Menu, Button, Tooltip, Typography } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, MessageOutlined, FileOutlined, DatabaseOutlined } from '@ant-design/icons';
import { useDatabase } from '../../contexts/DatabaseContext';
import '../../assets/styles/sidenav.css';

const { SubMenu } = Menu;
const { Title } = Typography;

function Sidenav({ color, chatPaths, addNewChat, deleteChat }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const { chatNames } = useDatabase();
  const [openKeys, setOpenKeys] = useState(['chats']);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  return (
    <div className="sidenav">
      <div className="brand">
        <img 
          src="https://img1.wsimg.com/isteam/ip/5d583d88-624b-4c3c-aa32-a179d2b6db6c/blob-e7831ec.png/:/rs=w:160,h:102,cg=true,m/cr=w:160,h:102/qt=q:95"
          alt="Personal AI Logo" 
          className="brand-logo"
        />
        <Title level={4} className="brand-title">DocuSearch</Title>
      </div>
      <div className="new-chat-container">
        <Button type="primary" onClick={addNewChat} icon={<PlusOutlined />} className="new-chat-button">
          New Chat
        </Button>
      </div>
      <Menu 
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        selectedKeys={[page]}
      className="sidenav-menu"
      >
        <SubMenu 
          key="chats" 
          icon={<MessageOutlined />} 
          title="Chats"
        >
          {chatPaths.map(({ key }) => (
            <Menu.Item key={key} className="chat-item">
              <div className="chat-item-content">
              <NavLink to={`/${key}`} className="chat-link">
                <span className="label">{chatNames[key] || 'New Chat'}</span>
              </NavLink>
              {chatPaths.length > 1 && (
                <Tooltip title="Delete chat">
                  <Button
                    type="text"
                      icon={<DeleteOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      deleteChat(key);
                    }}
                  className="delete-chat-button"
                    />
                </Tooltip>
              )}
              </div>
            </Menu.Item>
          ))}
        </SubMenu>
        <Menu.Item key="KnowldgeBase" >
          <NavLink to="/KnowldgeBase" className="menu-link">
            <DatabaseOutlined />
            <span className="label">KnowldgeBase</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="reports" >
          <NavLink to="/reports" className="menu-link">
            <FileOutlined />
            <span className="label">Reports</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    </div>
  );
}

export default Sidenav;
