import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { cognitoConfig } from '../config/congnitoConfig';
import AWS from 'aws-sdk';
import axios from 'axios';

AWS.config.update({ region: cognitoConfig.Region });

const API_BASE_URL = 'https://xigciaghfi.execute-api.us-east-1.amazonaws.com/PreProd'; // Replace with your actual API Gateway URL

const userPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.UserPoolId,
  ClientId: cognitoConfig.ClientId
});


export const cognitoService = {
  signIn: (username, password) => {
    return new Promise((resolve, reject) => {
      if (!username || !password) {
        reject(new Error('Username and password are required'));
        return;
      }

      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => resolve(result),
        onFailure: (err) => reject(err),
      });
    });
  },

  signOut: () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
    }
  },

  getCurrentUser: () => {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();
      if (!cognitoUser) {
        reject(new Error('No user found'));
        return;
      }

      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
          return;
        }
        cognitoUser.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err);
          } else {
            const userData = attributes.reduce((acc, attribute) => {
              acc[attribute.Name] = attribute.Value;
              return acc;
            }, {});
            const groups = session.getIdToken().payload['cognito:groups'] || [];
            resolve({ ...userData, username: cognitoUser.getUsername(), groups });
          }
        });
      });
    });
  },

  signUp: (username, password, email) => {
    return new Promise((resolve, reject) => {
      userPool.signUp(username, password, [new CognitoUserAttribute({ Name: 'email', Value: email })], null, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  },

  confirmSignUp: (username, code) => {
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(code, true, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  },

  forgotPassword: (username) => {
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (data) => resolve(data),
        onFailure: (err) => reject(err)
      });
    });
  },

  forgotPasswordSubmit: (username, code, newPassword) => {
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool });
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: () => resolve(),
        onFailure: (err) => reject(err)
      });
    });
  },

  changePassword: (oldPassword, newPassword) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();
      if (!cognitoUser) {
        reject(new Error('No user found'));
        return;
      }

      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
          return;
        }

        cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        });
      });
    });
  },

  updateUserAttributes: (attributes) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();
      if (!cognitoUser) {
        reject(new Error('No user found'));
        return;
      }
  
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.error('Session error:', err);
          reject(err);
          return;
        }
  
        console.log('Attributes to update:', attributes);
  
        const attributeList = Object.keys(attributes).map(key => 
          new CognitoUserAttribute({ Name: key, Value: attributes[key] })
        );
  
        cognitoUser.updateAttributes(attributeList, (err, result) => {
          if (err) {
            console.error('Error updating attributes:', err);
            console.error('Error code:', err.code);
            console.error('Error message:', err.message);
            if (err.code === 'NotAuthorizedException') {
              reject(new Error(`You are not authorized to update these attributes: ${Object.keys(attributes).join(', ')}. Please contact support.`));
            } else {
              reject(err);
            }
          } else {
            console.log('Attributes updated successfully:', result);
            resolve(result);
          }
        });
      });
    });
  },

  isUserInGroup: (user, group) => {
    return user && user.groups && user.groups.includes(group);
  },

  getSession: () => {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();
      if (!cognitoUser) {
        reject(new Error('No user found'));
        return;
      }

      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });
  },

  getTokens: () => {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();
      if (!cognitoUser) {
        reject(new Error('No user found'));
        return;
      }

      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          resolve({
            accessToken: session.getAccessToken().getJwtToken(),
            idToken: session.getIdToken().getJwtToken(),
            refreshToken: session.getRefreshToken().getToken()
          });
        }
      });
    });
  },

  resendConfirmationCode: (username) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool
      });

      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  },

  googleSignIn: (idToken) => {
    return new Promise((resolve, reject) => {
      AWS.config.region = cognitoConfig.Region;

      const cognitoidentity = new AWS.CognitoIdentity();
      const params = {
        IdentityPoolId: cognitoConfig.IdentityPoolId,
        Logins: {
          'accounts.google.com': idToken
        }
      };

      cognitoidentity.getId(params, (err, data) => {
        if (err) {
          console.error('Error getting identity ID:', err);
          reject(err);
          return;
        }

        const cognitoIdentityId = data.IdentityId;
        console.log('Cognito Identity ID:', cognitoIdentityId);

        cognitoidentity.getCredentialsForIdentity({
          IdentityId: cognitoIdentityId,
          Logins: {
            'accounts.google.com': idToken
          }
        }, (err, data) => {
          if (err) {
            console.error('Error getting credentials:', err);
            reject(err);
            return;
          }

          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityId: cognitoIdentityId,
            Logins: {
              'accounts.google.com': idToken
            }
          });

          const cognitoUser = userPool.getCurrentUser();
          if (!cognitoUser) {
            reject(new Error('No user found after Google sign-in'));
            return;
          }

          cognitoUser.getSession((err, session) => {
            if (err) {
              console.error('Error getting session:', err);
              reject(err);
            } else {
              resolve(session);
            }
          });
        });
      });
    });
  },

  updateUserOrganization: (organizationValue) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();
      if (!cognitoUser) {
        reject(new Error('No user found'));
        return;
      }
  
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
          return;
        }
  
        const attributeList = [
          new CognitoUserAttribute({
            Name: 'custom:Organization',
            Value: organizationValue
          })
        ];
  
        console.log('Attempting to update organization:', organizationValue);
  
        cognitoUser.updateAttributes(attributeList, (err, result) => {
          if (err) {
            console.error('Error updating organization:', err);
            if (err.code === 'NotAuthorizedException') {
              reject(new Error('You are not authorized to update the organization. Please contact support.'));
            } else {
              reject(err);
            }
          } else {
            console.log('Organization updated successfully');
            resolve(result);
          }
        });
      });
    });
  },

  getUserOrganizationAttributes: () => {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();
      if (!cognitoUser) {
        reject(new Error('No user found'));
        return;
      }

      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
          return;
        }

        cognitoUser.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err);
          } else {
            const organizationAttributes = attributes
              .filter(attr => 
                attr.Name === 'custom:Organization' || 
                attr.Name === 'custom:Role' || 
                attr.Name === 'custom:JoinedDate' || 
                attr.Name.startsWith('custom:Org_')
              )
              .map(attr => ({
                name: attr.Name,
                value: attr.Value,
              }));

            resolve(organizationAttributes);
          }
        });
      });
    });
  },

  validateInviteCode: async (inviteCode) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/InviteCode/validate`, { code: inviteCode });
      return response.data;
    } catch (error) {
      console.error('Error validating invite code:', error);
      throw error;
    }
  },

  joinOrganization: async (inviteCode) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();
      if (!cognitoUser) {
        reject(new Error('No user found'));
        return;
      }
  
      cognitoUser.getSession(async (err, session) => {
        if (err) {
          reject(err);
          return;
        }
  
        try {
          const token = session.getIdToken().getJwtToken();
          const response = await axios.post(`${API_BASE_URL}/InviteCode/join`,
            { inviteCode },
            {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            }
          );
          
          if (response.data.message === 'Successfully joined the organization') {
            resolve(response.data);
          } else {
            reject(new Error('Failed to join organization'));
          }
        } catch (error) {
          reject(error);
        }
      });
    });
  },
  isOrganizationEditable: () => {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();
      if (!cognitoUser) {
        reject(new Error('No user found'));
        return;
      }

      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
          return;
        }

        // Check if the user has the necessary permissions to edit the organization
        // This is a placeholder implementation. You should implement your own logic here.
        const userGroups = session.getIdToken().payload['cognito:groups'] || [];
        const canEditOrganization = userGroups.includes('admin') || userGroups.includes('org_manager');
        
        resolve(canEditOrganization);
      });
    });
  },
  getS3Credentials: async () => {
    try {
      const session = await cognitoService.getSession();
      const token = session.getIdToken().getJwtToken();
      const response = await axios.get(`${API_BASE_URL}/get-s3-credentials`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      console.error('Error getting S3 credentials:', error);
      throw error;
    }
  },

  syncDocumentStructure: async () => {
    try {
      const credentials = await cognitoService.getS3Credentials();
      const s3 = new AWS.S3({
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
        region: cognitoConfig.Region
      });

      const params = {
        Bucket: credentials.bucket,
        Delimiter: '/'
      };

      const data = await s3.listObjectsV2(params).promise();
      return data.Contents.map(item => ({
        key: item.Key,
        lastModified: item.LastModified,
        size: item.Size
      }));
    } catch (error) {
      console.error('Error syncing document structure:', error);
      throw error;
    }
  },

  addDocumentToS3: async (file) => {
    try {
      const credentials = await cognitoService.getS3Credentials();
      const s3 = new AWS.S3({
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
        region: cognitoConfig.Region
      });

      const params = {
        Bucket: credentials.bucket,
        Key: file.name,
        Body: file,
        ContentType: file.type
      };

      await s3.upload(params).promise();
      return { message: 'File uploaded successfully' };
    } catch (error) {
      console.error('Error adding document to S3:', error);
      throw error;
    }
  },
  getFiles: async () => {
    try {
      const session = await cognitoService.getSession();
      const token = session.getIdToken().getJwtToken();
      const response = await axios.get(`${API_BASE_URL}/files`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching files:', error);
      throw error;
    }
  },

  getVectorStores: async () => {
    try {
      const session = await cognitoService.getSession();
      const token = session.getIdToken().getJwtToken();
      const response = await axios.get(`${API_BASE_URL}/vectorStores`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching vector stores:', error);
      throw error;
    }
  },

  syncKnowledgeBase: async () => {
    try {
      const session = await cognitoService.getSession();
      const token = session.getIdToken().getJwtToken();
      const response = await axios.post(`${API_BASE_URL}/sync`, {}, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      console.error('Error syncing knowledge base:', error);
      throw error;
    }
  },
};