import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000/api';

export const getAuthHeader = () => ({
  'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
  'Content-Type': 'application/json'
});

const api = axios.create({
  baseURL: BASE_URL,
  headers: getAuthHeader(),
});

export const apiUtils = {
  get: async (url, config = {}) => {
    try {
      const response = await api.get(url, config);
      return response.data;
    } catch (error) {
      console.error('GET request failed:', error);
      throw error;
    }
  },

  post: async (url, data, config = {}) => {
    try {
      const response = await api.post(url, data, config);
      return response.data;
    } catch (error) {
      console.error('POST request failed:', error);
      throw error;
    }
  },

  // Add other methods (put, delete, patch) as needed
};
