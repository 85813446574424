import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { DatabaseProvider } from "./contexts/DatabaseContext";
import { AuthProvider } from './contexts/AuthContext';
import App from "./App";
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <DatabaseProvider>
        <App />
      </DatabaseProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById("root")
);