// src/components/auth/ChangePassword.js
import React from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import '../../assets/styles/auth.css';
import LandingPage from './LandingPage';

const ChangePassword = () => {
  const { changePassword } = useAuth();
  const history = useHistory();

  const onFinish = async (values) => {
    try {
      await changePassword(values.oldPassword, values.newPassword);
      message.success('Password changed successfully!');
      history.push('/'); // Redirect to home page after successful password change
    } catch (error) {
      console.error('Change password error:', error);
      message.error('Failed to change password. Please try again.');
    }
  };

  return (
    <LandingPage>
    <div className="auth-container">
      <Card title="Change Password" className="auth-card">
        <Form
          name="change-password"
          className="auth-form"
          onFinish={onFinish}
        >
          <Form.Item
            name="oldPassword"
            rules={[{ required: true, message: 'Please input your old password!' }]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Old Password" />
          </Form.Item>
          <Form.Item
            name="newPassword"
            rules={[{ required: true, message: 'Please input your new password!' }]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="New Password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={['newPassword']}
            rules={[
              { required: true, message: 'Please confirm your new password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Confirm New Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="auth-form-button">
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
    </LandingPage>
  );
};

export default ChangePassword;