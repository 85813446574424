import React, { useContext } from 'react';
import { Card, Spin, Tooltip, Button,message } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import '../assets/styles/contextsider.css'; // Import the CSS file

import { AuthContext } from '../contexts/AuthContext'; // Import AuthContext


const ContextSider = ({ chatId, searchResults, handlePdfRetrieval, setPdfPage }) => {
  const { user } = useContext(AuthContext);
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };
  const handleButtonClick = async (filename, pageNumber) => {
    if (!user || !user['custom:Organization']) {
      console.error('Organization ID not available');
      message.error('Unable to retrieve PDF. Organization information is missing.');
      return;
    }

    const organizationId = user['custom:Organization'];

    try {
      await handlePdfRetrieval(organizationId, filename, pageNumber);
      setPdfPage(pageNumber + 1);
    } catch (error) {
      console.error('Error retrieving PDF:', error);
      message.error('Failed to retrieve PDF. Please try again later.');
    }
  };

  if (!user) {
    return <Card title="Relevant Context" bordered={false} className="context-sider">
      <p>Please log in to view context.</p>
    </Card>;
  }

  return (
    <Card title="Relevant Context" bordered={false} className="context-sider">
      {searchResults.loading ? (
        <Spin tip="Updating context..." />
      ) : (
        (searchResults[chatId] || []).map((result, index) => (
          <Tooltip
            key={`${result.filename}-${index}`}
            title={
              result.matches && result.matches.length > 0 ? (
                <div>
                  <p><strong>{result.matches[0].metadata.title}</strong></p>
                  <p>{result.matches[0].metadata.text}</p>
                </div>
              ) : `${result.filename} (Page ${result.pageNumber})`
            }
          >
            <Button
              className="pdf-nav-button"
              icon={<FilePdfOutlined />}
              onClick={() => handleButtonClick(result.filename, result.pageNumber)}
            >
              {result.matches && result.matches.length > 0
                ? truncateText(result.matches[0].metadata.text, 10)
                : `Page ${result.pageNumber}`}
            </Button>
          </Tooltip>
        ))
      )}
    </Card>
  );
};

export default ContextSider;