// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { cognitoService } from '../services/cognitoService';
import { message } from 'antd';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [documentStructure, setDocumentStructure] = useState([]);
  const [files, setFiles] = useState([]);
  const [vectorStores, setVectorStores] = useState([]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const currentUser = await cognitoService.getCurrentUser();
        setUser(currentUser);
      } catch {
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
    fetchCurrentUser();
  }, []);

  const fetchData = async () => {
    try {
      const filesData = await cognitoService.getFiles();
      const vectorStoresData = await cognitoService.getVectorStores();
      setFiles(filesData);
      setVectorStores(vectorStoresData);
    } catch (error) {
      message.error('Error fetching data: ' + error.message);
    }
  };

  const handleSync = async () => {
    try {
      await cognitoService.syncKnowledgeBase();
      await fetchData();
      message.success('Knowledge base synced successfully');
    } catch (error) {
      message.error('Error syncing knowledge base: ' + error.message);
    }
  };

  const value = {
    user,
    loading,
    files,
    vectorStores,
    documentStructure,
    fetchData,
    handleSync,
    signIn: async (username, password) => {
      if (!username || !password) {
        throw new Error('Username and password are required');
      }
      try {
        await cognitoService.signIn(username, password);
        setUser(await cognitoService.getCurrentUser());
        message.success('Successfully signed in!');
      } catch (error) {
        message.error('Sign in failed: ' + error.message);
        throw error;
      }
    },
    signOut: async () => {
      try {
        await cognitoService.signOut();
        setUser(null);
        message.success('Successfully signed out!');
      } catch (error) {
        message.error('Sign out failed: ' + error.message);
        throw error;
      }
    },
    signUp: async (username, password, email) => {
      if (!username || !password || !email) {
        throw new Error('Username, password, and email are required');
      }
      try {
        await cognitoService.signUp(username, password, email);
        message.success('Sign up successful! Please check your email to confirm your account.');
      } catch (error) {
        message.error('Sign up failed: ' + error.message);
        throw error;
      }
    },
    confirmSignUp: async (username, code) => {
      try {
        await cognitoService.confirmSignUp(username, code);
        message.success('Account confirmed successfully!');
      } catch (error) {
        message.error('Confirmation failed: ' + error.message);
        throw error;
      }
    },
    resendConfirmationCode: async (username) => {
      try {
        await cognitoService.resendConfirmationCode(username);
        message.success('A new confirmation code has been sent to your email.');
      } catch (error) {
        message.error('Failed to resend confirmation code: ' + error.message);
        throw error;
      }
    },
    forgotPassword: async (username) => {
      try {
        await cognitoService.forgotPassword(username);
        message.success('Password reset code sent to your email.');
      } catch (error) {
        message.error('Failed to initiate password reset: ' + error.message);
        throw error;
      }
    },
    forgotPasswordSubmit: async (username, code, newPassword) => {
      try {
        await cognitoService.forgotPasswordSubmit(username, code, newPassword);
        message.success('Password reset successfully.');
      } catch (error) {
        message.error('Failed to reset password: ' + error.message);
        throw error;
      }
    },
    changePassword: async (oldPassword, newPassword) => {
      try {
        await cognitoService.changePassword(oldPassword, newPassword);
        message.success('Password changed successfully.');
      } catch (error) {
        message.error('Failed to change password: ' + error.message);
        throw error;
      }
    },
    getUserOrganizationAttributes: async () => {
      try {
        const attributes = await cognitoService.getUserOrganizationAttributes();
        return attributes;
      } catch (error) {
        message.error('Failed to fetch organization attributes: ' + error.message);
        throw error;
      }
    },
    updateUserAttributes: async (attributes) => {
      try {
        await cognitoService.updateUserAttributes(attributes);
        setUser(await cognitoService.getCurrentUser()); // Update user data
        message.success('Profile updated successfully');
      } catch (error) {
        message.error('Failed to update profile: ' + error.message);
        throw error;
      }
    },
    validateInviteCode: async (inviteCode) => {
      try {
        const result = await cognitoService.validateInviteCode(inviteCode);
        return result;
      } catch (error) {
        message.error('Failed to validate invite code: ' + error.message);
        throw error;
      }
    },
    joinOrganization: async (inviteCode) => {
      try {
        await cognitoService.joinOrganization(inviteCode);
        setUser(await cognitoService.getCurrentUser()); // Update user data
        message.success('Successfully joined the organization!');
      } catch (error) {
        message.error('Failed to join organization: ' + error.message);
        throw error;
      }
    },
    syncDocumentStructure: async () => {
      try {
        const structure = await cognitoService.syncDocumentStructure();
        setDocumentStructure(structure);
        message.success('Document structure synced successfully');
        return structure;
      } catch (error) {
        message.error('Failed to sync document structure: ' + error.message);
        throw error;
      }
    },
    addDocument: async (file) => {
      try {
        await cognitoService.addDocumentToS3(file);
        message.success('Document added successfully');
        // Refresh the document structure after adding a new document
        await value.syncDocumentStructure();
      } catch (error) {
        message.error('Failed to add document: ' + error.message);
        throw error;
      }
    },
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};