import React from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import '../../assets/styles/auth.css';
import LandingPage from './LandingPage';


const Login = () => {
  const { signIn } = useAuth();
  const history = useHistory();

  const onFinish = async (values) => {
    try {
      await signIn(values.username, values.password);
      history.push('/'); // Redirect to desired path
    } catch (error) {
      console.error('Login error:', error);
      message.error('Login failed. Please try again.');
    }
  };

  return (
    <LandingPage>
    <div className="auth-container">
      <Card title="Login" className="auth-card">
        <Form
          name="login"
          className="auth-form"
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your Username!' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="auth-form-button">
              Log in
            </Button>
          </Form.Item>
        </Form>
        <div className="auth-links">
          <Link to="/forgot-password">Forgot password?</Link>
          <br />
          <Link to="/forgot-username">Forgot username?</Link>
          <br />
          Don't have an account? <Link to="/signup">Sign up now!</Link>
        </div>
      </Card>
    </div>
    </LandingPage>
  );
};

export default Login;