import React from "react";
import { Layout, Row, Col, Space, Tooltip } from "antd";
import { GithubOutlined, LinkedinOutlined, TwitterOutlined } from "@ant-design/icons";
import "../../assets/styles/footer.css";

const { Footer: AntFooter } = Layout;

const SocialIcon = ({ icon: Icon, tooltip, link }) => (
  <Tooltip title={tooltip}>
    <a href={link} target="_blank" rel="noopener noreferrer" className="social-icon">
      <Icon />
    </a>
  </Tooltip>
);

const Footer = ({ companyName = "Urbanltyics Solutions", year = new Date().getFullYear() }) => {
  return (
    <AntFooter className="footer">
      <Row justify="space-between" align="middle">
        <Col>
          <span className="footer-content">
            © {year} Made by{' '}
            <span className="company-name">{companyName}</span>
          </span>
        </Col>
        <Col>
          <Space className="social-icons" size="middle">
            <SocialIcon icon={LinkedinOutlined} tooltip="LinkedIn" link="https://linkedin.com" />
          </Space>
        </Col>
      </Row>
    </AntFooter>
  );
};

export default Footer;