import React, { useState, useEffect } from 'react';
import { Dropdown, Slider, Button, Tooltip, Select, message } from 'antd';
import { EllipsisOutlined, InfoCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { useDatabase } from '../../contexts/DatabaseContext';
import '../../assets/styles/settings.css';

const { Option } = Select;

const Settings = () => {
  const { 
    searchRadius, 
    setSearchRadius, 
    vectorStores, 
    selectedVectorStore, 
    setSelectedVectorStore,
    syncKnowledgeBase
  } = useDatabase();

  const [isSyncing, setIsSyncing] = useState(false);

  useEffect(() => {
    // Auto-select the vector store if there's only one
    if (vectorStores.length === 1 && !selectedVectorStore) {
      setSelectedVectorStore(vectorStores[0].id);
    }
    console.log('Vector stores:', selectedVectorStore);
  }, [vectorStores, selectedVectorStore, setSelectedVectorStore]);

  const handleSliderChange = (value) => {
    setSearchRadius(value);
  };

  const handleVectorStoreChange = (value) => {
    setSelectedVectorStore(value);
    console.log('Selected vector store:', value);
  };

  const handleSyncKnowledgeBase = async () => {
    setIsSyncing(true);
    try {
      await syncKnowledgeBase();
      message.success({
        content: 'Sync completed successfully!',
        duration: 3,
      });
    } catch (error) {
      console.error('Error syncing knowledge base:', error);
      message.error({
        content: 'Failed to sync knowledge base. Please try again.',
        duration: 3,
      });
    } finally {
      setIsSyncing(false);
    }
  };

  const menu = (
    <div className="settings-dropdown">
      <h3>Settings</h3>
      <div className="search-radius-section">
        <h4>Search Range</h4>
        <Slider
          className="search-radius-slider"
          min={5}
          max={30}
          step={5}
          onChange={handleSliderChange}
          value={searchRadius.toFixed(0)}
        />
        <div className="search-radius-value">
          <span>Search Range: {searchRadius.toFixed(2)}</span>
          <Tooltip title="The search range controls how many matches are returned from the data. It dictates how exhaustive the result is.">
            <InfoCircleOutlined style={{ marginLeft: 8 }} />
          </Tooltip>
        </div>
      </div>
      <div className="vector-store-section">
        <h4>Vector Store</h4>
        <Select
          style={{ width: '100%' }}
          placeholder="Select a vector store"
          value={selectedVectorStore}
          onChange={handleVectorStoreChange}
        >
          {vectorStores.map(store => (
            <Option key={store.id} value={store.id}>{store.name}</Option>
          ))}
        </Select>
        <Button 
          icon={<SyncOutlined spin={isSyncing} />}
          onClick={handleSyncKnowledgeBase}
          style={{ marginTop: '10px', width: '100%' }}
          loading={isSyncing}
        >
          {isSyncing ? 'Syncing...' : 'Sync Knowledge Base'}
        </Button>
      </div>
    </div>
  );

  return (
    <Dropdown 
      overlay={menu} 
      trigger={['click']} 
      placement="bottomRight"
      overlayClassName="settings-dropdown-overlay"
    >
      <Button className="rotated-button" icon={<EllipsisOutlined />} />
    </Dropdown>
  );
};

export default Settings;