import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import Chat from "./pages/Chat";
import KnowldgeBase from "./pages/KnowldgeBase";
import Reports from "./pages/Reports";
import Login from "./components/auth/Login";
import Signup from "./components/auth/Signup";
import ConfirmSignUp from "./components/auth/ConfirmSignUp";
import ForgotPassword from "./components/auth/ForgotPassword";
import ForgotUsername from "./components/auth/ForgotUsername";
import ChangePassword from "./components/auth/ChangePassword";
import Main from "./components/layout/Main";
import { useDatabase } from './contexts/DatabaseContext';
import PrivateRoute from "./utils/PrivateRoute";
import { useAuth } from './hooks/useAuth';
import "antd/dist/antd.css";
import "./assets/styles/main.css";

function App() {
  const [chatPaths, setChatPaths] = useState([]);
  const { addNewChat, deleteChat, chatStreams, chatNames } = useDatabase();
  const { user, loading } = useAuth();

  useEffect(() => {
    if (user) {
      const existingChatPaths = Object.keys(chatStreams).map(chatId => ({
        key: chatId,
        label: chatNames[chatId] || `Chat ${chatId.slice(0, 8)}...`
      }));

      if (existingChatPaths.length === 0) {
        handleAddNewChat();
      } else {
        setChatPaths(existingChatPaths);
      }
    }
  }, [user, chatStreams, chatNames]);

  const handleAddNewChat = () => {
    const newChatKey = `chat-${uuidv4()}`;
    const newChatPath = { key: newChatKey, label: `New Chat` };
    setChatPaths(prevPaths => [...prevPaths, newChatPath]);
    addNewChat(newChatKey);
  };

  const handleDeleteChat = (chatKey) => {
    setChatPaths(prevPaths => prevPaths.filter(path => path.key !== chatKey));
    deleteChat(chatKey);
    if (chatPaths.length === 1) {
      handleAddNewChat();
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <Switch>
        <Route path="/login">
          {user ? <Redirect to="/" /> : <Login />}
        </Route>
        <Route path="/signup">
          {user ? <Redirect to="/" /> : <Signup />}
        </Route>
        <Route path="/confirm-signup">
          {user ? <Redirect to="/" /> : <ConfirmSignUp />}
        </Route>
        <Route path="/forgot-password">
          {user ? <Redirect to="/" /> : <ForgotPassword />}
        </Route>
        <Route path="/forgot-username">
          {user ? <Redirect to="/" /> : <ForgotUsername />}
        </Route>
        <PrivateRoute path="/change-password">
          <ChangePassword />
        </PrivateRoute>
        <PrivateRoute path="/">
          <Main chatPaths={chatPaths} addNewChat={handleAddNewChat} deleteChat={handleDeleteChat}>
            <Switch>
              {chatPaths.map(chat => (
                <Route exact path={`/${chat.key}`} key={chat.key}>
                  <Chat chatId={chat.key} />
                </Route>
              ))}
              <Route exact path="/KnowldgeBase" component={KnowldgeBase} />
              <Route exact path="/reports" component={Reports} />
              {chatPaths.length > 0 && <Redirect from="*" to={`/${chatPaths[0].key}`} />}
            </Switch>
          </Main>
        </PrivateRoute>
        <Redirect to="/login" />
      </Switch>
    </div>
  );
}

export default App;