import axios from 'axios';

const API_BASE_URL = 'https://api.stripe.com/v1';

const STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_SECRET_KEY;

if (!STRIPE_SECRET_KEY) {
  console.error('Stripe Secret Key is not set. Please check your environment variables.');
}

// Create an axios instance with default config
const stripeApi = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Authorization': `Bearer ${STRIPE_SECRET_KEY}`,
    'Content-Type': 'application/x-www-form-urlencoded'
  },
});

// Helper function to handle errors
const handleError = (error) => {
  console.error('Stripe API Error:', error.response ? error.response.data : error.message);
  throw error;
};

export const fetchBillingInfo = async (customerId) => {
  if (!customerId) {
    throw new Error('Customer ID is required');
  }
  try {
    const response = await stripeApi.get(`/customers/${customerId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const updatePaymentMethod = async (customerId, paymentMethodId) => {
  try {
    const response = await stripeApi.post(`/customers/${customerId}`, new URLSearchParams({
      invoice_settings: { default_payment_method: paymentMethodId }
    }));
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getInvoices = async (customerId, limit = 10) => {
  try {
    const response = await stripeApi.get('/invoices', {
      params: { customer: customerId, limit },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const getSubscriptionDetails = async (subscriptionId) => {
  try {
    const response = await stripeApi.get(`/subscriptions/${subscriptionId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};