import React, { useState } from 'react';
import { Form, Input, Button, Card, message, Progress } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import '../../assets/styles/auth.css';
import LandingPage from './LandingPage';

// Detailed password policy
const passwordPolicy = {
  minLength: 8,
  uppercase: true,
  lowercase: true,
  numbers: true,
  symbols: true,
};

const validatePassword = (password) => {
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasSymbols = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const minLength = password.length >= passwordPolicy.minLength;

  return {
    isValid: hasUpperCase && hasLowerCase && hasNumbers && hasSymbols && minLength,
    checks: {
      hasUpperCase,
      hasLowerCase,
      hasNumbers,
      hasSymbols,
      minLength,
    },
  };
};

const PasswordStrengthFeedback = ({ password }) => {
  const { isValid, checks } = validatePassword(password);
  const strength = Object.values(checks).filter(Boolean).length;

  return (
    <div>
      <Progress percent={strength * 20} status={isValid ? 'success' : 'active'} showInfo={false} />
      <ul style={{ paddingLeft: '20px', marginTop: '10px' }}>
        <li style={{ color: checks.minLength ? 'green' : 'red' }}>
          At least {passwordPolicy.minLength} characters
        </li>
        <li style={{ color: checks.hasUpperCase ? 'green' : 'red' }}>
          At least one uppercase letter
        </li>
        <li style={{ color: checks.hasLowerCase ? 'green' : 'red' }}>
          At least one lowercase letter
        </li>
        <li style={{ color: checks.hasNumbers ? 'green' : 'red' }}>
          At least one number
        </li>
        <li style={{ color: checks.hasSymbols ? 'green' : 'red' }}>
          At least one special character
        </li>
      </ul>
    </div>
  );
};

const Signup = () => {
  const { signUp } = useAuth();
  const history = useHistory();
  const [password, setPassword] = useState('');

  const onFinish = async (values) => {
    const { username, password, email } = values;

    const { isValid } = validatePassword(password);
    if (!isValid) {
      message.error('Password does not meet all requirements. Please check the list below.');
      return;
    }

    try {
      await signUp(username, password, email);
      message.success('Signup successful! Please check your email to confirm your account.');
      history.push('/confirm-signup', { email }); // Pass email to the confirm page
    } catch (error) {
      console.error('Signup error:', error);
      
      if (error.code === 'UsernameExistsException') {
        message.warning('An account with this email already exists. Please confirm your account.');
        history.push('/confirm-signup', { email }); // Pass email to the confirm page
      } else {
        message.error('Signup failed: ' + error.message);
      }
    }
  };

  return (
    <LandingPage>
    <div className="auth-container">
      <Card title="Sign Up" className="auth-card">
        <Form
          name="signup"
          className="auth-form"
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your Username!' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your Email!' },
              { type: 'email', message: 'Please enter a valid email!' }
            ]}
          >
            <Input prefix={<MailOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input.Password 
              prefix={<LockOutlined />} 
              placeholder="Password" 
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>
          <PasswordStrengthFeedback password={password} />
          <Form.Item
            name="confirm"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Please confirm your password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="Confirm Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="auth-form-button">
              Sign up
            </Button>
          </Form.Item>
        </Form>
        <div className="auth-links">
          Already have an account? <Link to="/login">Login now!</Link>
        </div>
      </Card>
    </div>
    </LandingPage>
  );
};

export default Signup;