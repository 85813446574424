import axios from 'axios';
import { cognitoService } from './cognitoService';

// Replace this with your actual API Gateway URL
const API_BASE_URL = 'https://xigciaghfi.execute-api.us-east-1.amazonaws.com/PreProd';

const createAuthenticatedRequest = async () => {
  try {
    const session = await cognitoService.getSession();
    const token = session.getIdToken().getJwtToken();
    return axios.create({
      baseURL: API_BASE_URL,
      headers: { 'Authorization': `Bearer ${token}` }
    });
  } catch (error) {
    console.error('Error creating authenticated request:', error);
    throw new Error('Failed to authenticate request');
  }
};

export const documentService = {

  retrievePdf: async (organizationId, fileName) => {
    if (!organizationId) {
      // Return an error message if organizationId is missing
      return {
        error: 'Missing organizationId. Please sync the knowledge base in the settings dropdown.'
      };
    }
    
    try {
      const authenticatedAxios = await createAuthenticatedRequest();
      const response = await authenticatedAxios.get(`/files/${organizationId}/${fileName}`);

      if (response.status === 200 && response.data && response.data.url) {
        return {
          pdfUrl: response.data.url,
          message: 'PDF URL retrieved successfully'
        };
      } else {
        throw new Error('The server did not return a valid PDF URL');
      }
    } catch (error) {
      console.error('Error retrieving PDF URL:', error);
      throw error;
    }
  },
  
  getFiles: async () => {
    try {
      const authenticatedAxios = await createAuthenticatedRequest();
      const response = await authenticatedAxios.get('/files');
      return response.data;
    } catch (error) {
      console.error('Error fetching files:', error);
      throw error;
    }
  },

  getVectorStores: async () => {
    try {
      const authenticatedAxios = await createAuthenticatedRequest();
      const response = await authenticatedAxios.get('/vectorStores');
      return response.data;
    } catch (error) {
      console.error('Error fetching vector stores:', error);
      throw error;
    }
  },

  syncKnowledgeBase: async () => {
    try {
      const authenticatedAxios = await createAuthenticatedRequest();
      const response = await authenticatedAxios.post('/sync');
      return response.data;
    } catch (error) {
      console.error('Error syncing knowledge base:', error);
      throw error;
    }
  },

 
};
