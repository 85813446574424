import React, { useState, useEffect, useCallback } from 'react';
import { Button, List, Space, Typography, Table, Divider, message } from 'antd';
import { FileOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons';
import { documentService } from '../services/documentService';
import '../assets/styles/knowledgebase.css';

const { Title, Text } = Typography;

const KnowledgeBase = () => {
  const [files, setFiles] = useState([]);
  const [vectorStores, setVectorStores] = useState([]);
  const [activeTab, setActiveTab] = useState('files');
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const [filesData, vectorStoresData] = await Promise.all([
        documentService.getFiles(),
        documentService.getVectorStores()
      ]);
      setFiles(filesData);
      setVectorStores(vectorStoresData);
      console.log('Fetched data:', filesData, vectorStoresData);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Failed to fetch data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSync = async () => {
    setLoading(true);
    try {
      await documentService.syncKnowledgeBase();
      await fetchData();
      message.success('Sync completed successfully');
    } catch (error) {
      console.error('Error syncing:', error);
      message.error('Failed to sync: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const renderFileDetails = () => (
    <div className="doc-details-panel">
      <Title level={4} className="doc-panel-title">FILE</Title>
      <Title level={3} className="doc-item-title">{selectedItem.key}</Title>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <div className="doc-detail-item">
          <Text type="secondary">Status</Text>
          <br />
          <Text strong className="doc-status-ready">Ready</Text>
        </div>
        <div className="doc-detail-item">
          <Text type="secondary">File ID</Text>
          <br />
          <Text strong>{selectedItem.id}</Text>
        </div>
        <div className="doc-detail-item">
          <Text type="secondary">Purpose</Text>
          <br />
          <Text strong>{selectedItem.type}</Text>
        </div>
        <div className="doc-detail-item">
          <Text type="secondary">Size</Text>
          <br />
          <Text strong>{selectedItem.size}</Text>
        </div>
        <div className="doc-detail-item">
          <Text type="secondary">Created at</Text>
          <br />
          <Text strong>{selectedItem.lastModified}</Text>
        </div>
      </Space>
    </div>
  );

  const renderVectorStoreDetails = () => {
    // Ensure selectedItem is defined and has attachedFiles
    const attachedFiles = selectedItem?.attachedFiles || [];

    // Transform attachedFiles data if available
    const attachedFilesData = attachedFiles.map((file, index) => ({
      key: index,
      file: file.split('/').pop(), // Extract file name from path
      uploaded: selectedItem.lastActive || 'N/A' // Use lastActive or default to 'N/A'
    }));

    return (
      <div className="doc-details-panel">
        <Title level={4} className="doc-panel-title">VECTOR STORE</Title>
        <Space align="center">
          <Title level={3} className="doc-item-title">{selectedItem.name || 'Untitled store'}</Title>
          <EditOutlined className="doc-edit-icon" />
        </Space>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <div className="doc-detail-item">
            <Text type="secondary">ID</Text>
            <br />
            <Text strong>{selectedItem.id}</Text>
          </div>
          <div className="doc-detail-item">
            <Text type="secondary">Usage this month</Text>
            <br />
            <Text strong>{selectedItem.usageThisMonth}</Text>
          </div>
          <div className="doc-detail-item">
            <Text type="secondary">Size</Text>
            <br />
            <Text strong>{selectedItem.size}</Text>
          </div>
          <div className="doc-detail-item">
            <Text type="secondary">Last active</Text>
            <br />
            <Text strong>{selectedItem.lastActive}</Text>
          </div>
          <div className="doc-detail-item">
            <Text type="secondary">Expiration policy</Text>
            <br />
            <Space>
              <Text strong>{selectedItem.expirationPolicy}</Text>
              <EditOutlined className="doc-edit-icon" />
            </Space>
          </div>
          <div className="doc-detail-item">
            <Text type="secondary">Expires</Text>
            <br />
            <Text strong>{selectedItem.expiresAt}</Text>
          </div>
          <div className="doc-detail-item">
            <Text type="secondary">Created</Text>
            <br />
            <Text strong>{selectedItem.createdAt}</Text>
          </div>
        </Space>
        <Title level={4} className="doc-section-title">Files attached</Title>
        <Table 
          className="doc-table"
          columns={[
            { title: 'FILE', dataIndex: 'file', key: 'file' },
            { title: 'UPLOADED', dataIndex: 'uploaded', key: 'uploaded' },
          ]} 
          dataSource={attachedFilesData}
          pagination={false}
          locale={{ emptyText: 'This vector store is empty.' }}
        />
        <Title level={4} className="doc-section-title">Used by</Title>
        <Table 
          className="doc-table"
          columns={[
            { title: 'Resource', dataIndex: 'resource', key: 'resource' },
            { title: 'ID', dataIndex: 'id', key: 'id' },
          ]} 
          dataSource={selectedItem.usedBy || []}
          pagination={false}
          locale={{ emptyText: 'Not used by any resources' }}
        />
      </div>
    );
  };

  return (
    <div className="doc-page">
      <div className="doc-tabs-container">
        <div className="doc-tabs">
          <Button 
            className={`doc-tab-btn ${activeTab === 'files' ? 'doc-tab-btn-active' : ''}`}
            onClick={() => setActiveTab('files')}
          >
            Files
          </Button>
          <Button 
            className={`doc-tab-btn ${activeTab === 'vectorStores' ? 'doc-tab-btn-active' : ''}`}
            onClick={() => setActiveTab('vectorStores')}
          >
            Knowledge Base
          </Button>
        </div>
        <Button icon={<SyncOutlined spin={loading} />} onClick={onSync} className="doc-sync-btn" loading={loading}>
          Sync
        </Button>
      </div>
      <div className="doc-content">
        <div className="doc-list-view">
          <List
            className="doc-list"
            dataSource={activeTab === 'files' ? files : vectorStores}
            renderItem={(item) => (
              <List.Item 
                key={item.id}
                onClick={() => setSelectedItem(item)}
                className={`doc-list-item ${selectedItem === item ? 'doc-list-item-selected' : ''}`}
              >
                <List.Item.Meta
                  avatar={activeTab === 'files' ? <FileOutlined className="doc-file-icon" /> : null}
                  title={<div className="doc-item-title">{activeTab === 'files' ? item.key : (item.name || 'Untitled store')}</div>}
                  description={<div className="doc-item-description">{`${activeTab === 'files' ? item.id:item.size }`}</div>}
                />
                <div className="doc-item-date">{activeTab === 'files' ? item.lastModified : item.lastActive}</div>
              </List.Item>
            )}
          />
          <Divider className="doc-divider" />
          <Button type="link" block className="doc-load-more-btn">Load more</Button>
        </div>
        {selectedItem && (
          activeTab === 'files' ? renderFileDetails() : renderVectorStoreDetails()
        )}
      </div>
    </div>
  );
};

export default KnowledgeBase;
