import React from 'react';
import { Modal, Tabs, Typography, List, Button, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 16px;
    background-color: white;
    color: #333;
  }
`;

const StyledList = styled(List)`
  .ant-list-item {
    padding: 4px 0;
  }
`;

const FeatureItem = ({ text, tooltip }) => (
  <List.Item>
    <Text>
      ✓ {text}
      {tooltip && (
        <Tooltip title={tooltip}>
          <InfoCircleOutlined style={{ color: '#1890ff', marginLeft: '5px' }} />
        </Tooltip>
      )}
    </Text>
  </List.Item>
);

const PlanModal = ({ visible, onCancel, currentPlan, upgradePlan }) => {
  const handleUpgrade = async (plan) => {
    try {
      await upgradePlan(plan);
      onCancel();
    } catch (error) {
      console.error('Error upgrading plan:', error);
    }
  };

  const renderPlanContent = (plan, features, price) => (
    <>
      <Title level={4}>{plan} Tier</Title>
      <Text strong>{price}</Text>
      <StyledList
        dataSource={features}
        renderItem={(item) => <FeatureItem text={item.text} tooltip={item.tooltip} />}
      />
      {currentPlan !== plan && (
        <Button type="primary" style={{ marginTop: '20px' }} onClick={() => handleUpgrade(plan)}>
          Upgrade to {plan}
        </Button>
      )}
    </>
  );

  return (
    <StyledModal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={800}
      title="Subscription Tiers"
    >
      <Tabs defaultActiveKey="basic">
        <TabPane tab="Basic" key="basic">
          {renderPlanContent("Basic", [
            { text: "1,000 PDF pages", tooltip: "Or equivalent text content" },
            { text: "1,000,000 tokens for embeddings" },
            { text: "100,000 read / 10,000 write ops per month" },
            { text: "10 MB per file, 100 MB total upload" },
            { text: "1 knowledge base, 10 queries/second" },
            { text: "Basic search capabilities" },
            { text: "99% uptime" },
          ], "$X/month")}
        </TabPane>
        <TabPane tab="Pro" key="pro">
          {renderPlanContent("Pro", [
            { text: "10,000 PDF pages", tooltip: "Or equivalent text content" },
            { text: "10,000,000 tokens for embeddings" },
            { text: "1,000,000 read / 100,000 write ops per month" },
            { text: "50 MB per file, 1 GB total upload" },
            { text: "3 knowledge bases, 100 queries/second" },
            { text: "Advanced search with metadata filtering" },
            { text: "99.9% uptime" },
          ], "$Y/month")}
        </TabPane>
        <TabPane tab="Enterprise" key="enterprise">
          {renderPlanContent("Enterprise", [
            { text: "Unlimited PDF pages", tooltip: "And equivalent text content" },
            { text: "Customizable token limits" },
            { text: "Unlimited read/write operations" },
            { text: "100 MB per file, 10 GB total upload" },
            { text: "Unlimited knowledge bases, custom QPS" },
            { text: "Advanced search, custom AI models" },
            { text: "99.99% uptime with custom SLA" },
          ], "Contact us for pricing")}
        </TabPane>
      </Tabs>
      
      <Text type="secondary" style={{ marginTop: '20px', display: 'block' }}>
        All plans include API access, data security, and scalability features. 
        Contact us for add-ons and custom requirements.
      </Text>
    </StyledModal>
  );
};

export default PlanModal;