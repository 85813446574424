import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Input, Button, List, Tooltip, Modal, Checkbox, message, Dropdown, Menu } from 'antd';
import { SendOutlined, CopyOutlined, BarChartOutlined, EllipsisOutlined } from '@ant-design/icons';
import DOMPurify from 'dompurify';
import { useHistory } from 'react-router-dom';
import { organizeContent } from '../services/openaiService';
import { useDatabase } from '../contexts/DatabaseContext';
import '../assets/styles/chatarea.css';

const { Search } = Input;

const ChatMainArea = ({ chatId, chatStreams, sendMessage, query, handleQuery, setSearchResults }) => {
  const [loading, setLoading] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const history = useHistory();
  const { addReport, searchRadius, setSearchRadius, syncDocumentsToContext } = useDatabase();

  // console.log(chatStreams[chatId]); 

  const handleSend = async (value) => {
    if (value.trim() === '') return;
    setLoading(true);
    setSearchResults({});
    try {
      await sendMessage(value, chatId);
      handleQuery('');
    } catch (error) {
      message.error('Failed to get response from AI. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = (content) => {
    navigator.clipboard.writeText(content)
      .then(() => message.success('Copied to clipboard'))
      .catch(() => message.error('Failed to copy'));
  };

  const handleMessageSelect = (id) => {
    setSelectedMessages((prevSelectedMessages) =>
      prevSelectedMessages.includes(id)
        ? prevSelectedMessages.filter((messageId) => messageId !== id)
        : [...prevSelectedMessages, id]
    );
  };

  const handleConvertToReport = async () => {
    if (selectedMessages.length === 0) {
      message.warning('Please select at least one message to convert to a report.');
      return;
    }

    Modal.confirm({
      title: 'Convert to Report',
      content: 'Do you want to convert the selected messages to a report?',
      async onOk() {
        const selectedContents = selectedMessages.map(id => {
          const message = chatStreams[chatId]?.find(msg => msg.id === id);
          return message ? message.content : '';
        }).filter(content => content);

        if (selectedContents.length === 0) {
          message.error('No valid messages selected for the report.');
          return;
        }

        try {
          const summarizedContent = await organizeContent(selectedContents.join('\n'));
          const parser = new DOMParser();
          const text = parser.parseFromString(summarizedContent, 'text/html');
          const newReport = {
            id: '_' + Math.random().toString(36).substr(2, 9),
            chatId,
            title: text.querySelector('title')?.textContent || 'No title found',
            original: selectedContents,
            summary: summarizedContent,
            timestamp: new Date().toISOString(),
          };

          // console.log('New report:', newReport);
          addReport(newReport);
          message.success('Report added successfully');
          history.push('/reports');
        } catch (error) {
          message.error('Failed to summarize the content. Please try again.');
        }
      },
    });
  };

  const handleSearchRadiusChange = ({ key }) => {
    setSearchRadius(parseInt(key, 10));
  };


  const renderMessageContent = useCallback((content) => {
    if (typeof content !== 'string') {
      return <div>{JSON.stringify(content)}</div>;
    }

    content = content.trim();

    const htmlCodeBlockRegex = /```html\s*([\s\S]*?)\s*```/;
    const match = content.match(htmlCodeBlockRegex);

    if (match) {
      const htmlContent = match[1].trim();
      const textBeforeHtml = content.slice(0, match.index).trim();
      const textAfterHtml = content.slice(match.index + match[0].length).trim();

      return (
        <>
          {textBeforeHtml && <div className="mb-4">{textBeforeHtml}</div>}
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(htmlContent, {
                ADD_ATTR: ['class'],
                ADD_CLASS: {
                  'table': 'border-collapse w-full',
                  'td': 'border border-gray-300 p-2',
                  'th': 'border border-gray-300 p-2 bg-gray-100'
                }
              })
            }}
          />
          {textAfterHtml && <div className="mt-4">{textAfterHtml}</div>}
        </>
      );
    }

    if (/<[a-z][\s\S]*>/i.test(content)) {
      return <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(content, {
            ADD_ATTR: ['class'],
            ADD_CLASS: {
              'table': 'border-collapse w-full',
              'td': 'border border-gray-300 p-2',
              'th': 'border border-gray-300 p-2 bg-gray-100'
            }
          })
        }}
      />;
    }

    return <div className="whitespace-pre-wrap">{content}</div>;
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatStreams, chatId]);


  return (
    <div className="chat-main-area">
      <div className="chat-message-area">
        <List
          className="message-list"
          dataSource={chatStreams[chatId] || []}
          renderItem={(item) => (
            <List.Item
              key={item.id}
              className={`message-item ${item.type} ${selectedMessages.includes(item.id) ? 'selected' : ''}`}
            >
              {item.type === 'ai' && (
                <Checkbox
                  checked={selectedMessages.includes(item.id)}
                  onChange={() => handleMessageSelect(item.id)}
                />
              )}
              <div className="message-content">
                {renderMessageContent(item.content)}
              </div>
              <Tooltip title="Copy message">
                <Button
                  icon={<CopyOutlined />}
                  size="small"
                  className="copy-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopy(item.content);
                  }}
                />
              </Tooltip>
            </List.Item>
          )}
        />
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input-area">
        <Search
          placeholder="Ask a question..."
          enterButton={
            <Button type="primary" icon={<SendOutlined />} loading={loading}>
              Send
            </Button>
          }
          size="large"
          value={query}
          onChange={(e) => handleQuery(e.target.value)}
          onSearch={handleSend}
          disabled={loading}
        />
        <Button
          icon={<BarChartOutlined />}
          onClick={handleConvertToReport}
          disabled={selectedMessages.length === 0}
        >
          Reporting
        </Button>
      </div>
    </div>
  );
};

export default ChatMainArea;
